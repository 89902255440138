import axios from 'axios';

import {
	GET_ASSOCIATION,
  GET_COLLECTES,
	GET_PARTICIPANTS_NUMBER,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const ASSOCIATION_URL = API_ROUTES.association;

const headers = localStorage.getItem('wisdana-customer-token') !== null ? { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }:{};

export function getAssociation(id) {
	return function(dispatch) {
		axios.get(`${ASSOCIATION_URL}${id}/`, {
			headers,
		})
		.then(response => {
			dispatch({ type: GET_ASSOCIATION, payload: response.data });
		})
		.catch(error => {
      console.log(error.response);
		});
	}
}

export function getCollectes(id) {
  return function(dispatch) {
    axios.get(`${ASSOCIATION_URL}${id}/collectes/`, {
      headers,
		})
		.then(response => {
			dispatch({ type: GET_COLLECTES, payload: response.data });
		})
		.catch(error => {
      console.log(error.response);
		});
  }
}

export function getParticipantsNumber(id) {
	return function(dispatch) {
		axios.get(`${ASSOCIATION_URL}${id}/participants-number/`, {
			headers,
		})
		.then(response => {
			dispatch({ type: GET_PARTICIPANTS_NUMBER, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		})
	}
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_ASSOCIATION, payload: null });
    dispatch({ type: GET_COLLECTES, payload: null });
		dispatch({ type: GET_PARTICIPANTS_NUMBER, payload: null });
  }
}
