import {
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM,
  RESET_PASSWORD_DONE,
} from './types';

import axios from 'axios';

import API_ROUTES from '../../utils/api_routes';

const PASSWORD_RESET_URL = API_ROUTES.password_reset;
const PASSWORD_RESET_CONFIRM_URL = API_ROUTES.password_reset_confirm;
const PASSWORD_RESET_DONE_URL = API_ROUTES.password_reset_done;

export function resetPassword({ email }) {
	return function(dispatch) {
		const email_transformed = email.trim();
		axios.post(`${PASSWORD_RESET_URL}`, { email: email_transformed })
		.then((response) => {
			dispatch({ type: RESET_PASSWORD, status: true, error: null, });
		})
		.catch((error) => {
			dispatch({ type: RESET_PASSWORD_FAIL, error: "Cet email n'est associé à aucun compte." });
		});
	}
}

export function confirmToken(location) {
	return function(dispatch) {
		axios.post(`${PASSWORD_RESET_CONFIRM_URL}`, location)
		.then((response) => {
			sessionStorage.setItem('wisdana-customer-token', response.data.token);

			dispatch({ type: RESET_PASSWORD_CONFIRM, confirmed: true, error: null });
		})
		.catch((error) => {
			dispatch({ type: RESET_PASSWORD_CONFIRM, confirmed: false, error: 'Ce lien est expiré.' });
		});
	}
}

export function resetPasswordDone(formdata) {
	return function(dispatch) {
		axios.post(`${PASSWORD_RESET_DONE_URL}`, formdata, {
			headers: { Authorization: `JWT ${sessionStorage.getItem('wisdana-customer-token')}` }
		})
		.then((response) => {
			dispatch({ type: RESET_PASSWORD_DONE, message: 'Votre mot de passe a bien été changé.' });
		})
		.catch((error) => {
			dispatch({ type: RESET_PASSWORD_DONE, error: 'Problème lors du changement de mot de passe.' })
		})
	}
}
