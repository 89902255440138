import React, { Component } from "react";
import PropTypes from "prop-types";

import { getPercent } from "./percent_calculation";
import Image from "./image";
import Tracker from "./tracker";

import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default class ProductCard extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    scrollPosition: PropTypes.object,
    addToCart: PropTypes.func.isRequired,
    terminer: PropTypes.bool.isRequired,
    association: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: {},
    color: "",
    scrollPosition: {},
    addToCart: () => {},
    terminer: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      error: false,
    };
  }

  addToCart = (data) => {
    const { quantity } = this.state;
    if (typeof quantity !== "number") {
      this.setState({ error: true });
      return false;
    }
    const obj = {
      ...data,
      quantity: this.state.quantity,
    };
    this.props.addToCart(obj);
  };

  changeQuantity = (e, quantity, quantity_available) => {
    e.preventDefault();
    if (parseInt(quantity) < 1) {
      this.setState({ quantity: 1, error: false });
    } else {
      if (quantity) {
        if (parseInt(quantity) > quantity_available) {
          this.setState({ quantity: quantity_available, error: false });
        } else {
          this.setState({ quantity: parseInt(quantity), error: false });
        }
      } else {
        this.setState({ error: true, quantity: "" });
      }
    }
  };

  decrement = (e, quantity) => {
    e.preventDefault();
    if (quantity > 1) {
      this.setState({ quantity: quantity - 1 });
    }
  };

  increment = (e, quantity, quantity_available) => {
    e.preventDefault();
    if (quantity + 1 <= quantity_available) {
      this.setState({ quantity: quantity + 1 });
    }
  };

  displayTracker = (data, color) => {
    if (data.quantity_available === 0 || data.quantity_available < 0) {
      return (
        <div
          className="product-tracker finished"
          style={{ backgroundColor: color }}
        >
          <p className="product-tracker-text finished">
            <span style={{ fontWeight: 700 }}>
              {data.quantity - data.quantity_available}
            </span>{" "}
            / {data.quantity}{" "}
            {data.quantity - data.quantity_available > 1
              ? "produits collectés"
              : "produit collecté"}
          </p>
          <Tracker
            color={"#fff"}
            percent={getPercent({
              total: data.quantity,
              quantity_available: data.quantity_available,
            })}
            height={6}
            radius={2}
          />
        </div>
      );
    }
    return (
      <div className="product-tracker">
        <p className="product-tracker-text">
          <span style={{ color: color, fontWeight: 700 }}>
            {data.quantity - data.quantity_available}
          </span>{" "}
          / {data.quantity}{" "}
          {data.quantity - data.quantity_available > 1
            ? "produits collectés"
            : "produit collecté"}
        </p>
        <Tracker
          color={color}
          percent={getPercent({
            total: data.quantity,
            quantity_available: data.quantity_available,
          })}
          height={4}
          radius={2}
        />
      </div>
    );
  };

  displayOffir = (data, available, error) => {
    if (data.article.disponible === false) {
      return <div className="cta finished">Indisponible</div>;
    }
    if (available === 0 || error || available < 0) {
      return <div className="cta finished">Ajouter au panier</div>;
    }
    return (
      <div className="cta" onClick={() => this.addToCart(data)}>
        Ajouter au panier
      </div>
    );
  };

  renderDefiscalisation = (data, association) => {
    if (association.defiscalisation_percent === 0) {
      return null;
    }

    const defisc = data.price_after_defiscalisation;
    return (
      <p>
        <Tooltip
          title={
            <span style={{ textAlign: "center" }}>
              Après avoir déclaré vos dons aux impôts, ce montant correspond à
              ce que vous aurez réellement payé. Pour plus d’informations,
              consultez la rubrique Fiscalité de notre FAQ.
            </span>
          }
          placement="bottom"
          color={"#2b8bbc"}
          overlayClassName="tooltip-defisc"
          overlayStyle={{ maxWidth: 350 }}
        >
          soit <span className="defiscalisation-price">{defisc}€</span> après
          défiscalisation <QuestionCircleOutlined />
        </Tooltip>
      </p>
    );
  };

  truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  render() {
    const { data, color, scrollPosition, terminer, association } = this.props;
    const { error } = this.state;

    return (
      <div className="product">
        <div className="image">
          <Image
            scrollPosition={scrollPosition}
            image={{
              alt: data.article.nom,
              height: 170,
              src: data.article.photo,
            }}
          />
        </div>
        {data.quantity !== 0 && this.displayTracker(data, color)}
        <h4 className="title">{this.truncate(data.article.nom, 50)}</h4>
        {terminer === false && (
          <div className="conditionnement">
            <h2>
              {/* {data.article.is_heavy
                ? parseFloat(data.price) + 2.0
                : data.price} */}
              {data.price}€
            </h2>
            {this.renderDefiscalisation(data, association)}
          </div>
        )}
        {terminer === false &&
          this.displayOffir(data, data.quantity_available, error)}
      </div>
    );
  }
}
