import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Image = ({ image }) =>
      <LazyLoadImage
        alt={image.alt}
        // height={image.height}
        effect="blur"
        src={image.src} />;

export default Image;
