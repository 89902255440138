import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FieldTimeOutlined } from '@ant-design/icons';

import Image from '../elements/image-single';
// import LazyBackground from '../elements/lazybackground';

class Description extends Component {
  static propTypes = {
    collecte: PropTypes.shape({
      association: PropTypes.object.isRequired,
      date_debut: PropTypes.string.isRequired,
      date_fin: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      distance: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.bool.isRequired]),
      id: PropTypes.number.isRequired,
      lieu_de_livraison: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      percent: PropTypes.object.isRequired,
      photo: PropTypes.string.isRequired,
      sous_theme: PropTypes.object,
      terminer: PropTypes.bool.isRequired,
      promue: PropTypes.bool.isRequired,
      theme: PropTypes.object.isRequired,
    }),
    color: PropTypes.string.isRequired,
  };

  static defaultProps = {
    collecte: null,
    color: '',
  };

  dateFin = (date_fin, terminer) => {
    if (terminer === true) {
      return "Terminée";
    }
    const now = new Date();
    const difference_in_time = new Date(date_fin).getTime() - now.getTime();
    const days = difference_in_time / (1000 * 3600 * 24);
    if (Math.round(days) > 0) {
        return `Plus que ${Math.ceil(days)} jours`;
    } else if (Math.round(days) === 0) {
      return "Aujourd'hui";
    }
    return "Terminée";
  }

  render() {
    const { collecte } = this.props;

    if (collecte === null) {
      return <div className="description-box skeleton"></div>;
    }

    return (
      <div className="description-box">
        <div className="description-box-upper">
          <div className="description-box-image">
            {collecte.portal_activated === false ? (
              <Image
                image={{
                  alt: collecte.nom,
                  height: 280,
                  src: collecte.photo
                }}
              />
            ) : null}
          </div>
          <div className="description-box-text">
            <div className="description-box-text-association">
              <div className="logo" style={{ backgroundImage: `url(${collecte.association.logo})` }}></div>
              <p style={{ fontWeight: 600, color: collecte.theme.couleur, marginBottom: 0 }}>{collecte.association.nom}</p>
            </div>
            {collecte.portal_activated === false ? (
              <h1 className="description-box-title">
                {collecte.nom}
              </h1>
            ) : null}

            {collecte.portal_activated === false ? (
              <p className="description-box-date">
                <FieldTimeOutlined style={{ marginRight: 5 }}/>
                {this.dateFin(collecte.date_fin, collecte.terminer)}
              </p>
            ) : null}

            {collecte.portal_activated === false ? (
              <p className="description-box-paragraph">
                {collecte.description}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Description;
