import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

export default class Bandeau extends Component {
  static propTypes = {
    theme: PropTypes.object.isRequired,
  };

  static defaultProps = {
    theme: {},
  };

  render() {
    const { theme } = this.props;

    const bandeau = classNames({
      bandeau: true,
    });

    return (
      <div className={bandeau} style={{ backgroundColor: theme.couleur }}>
        <div className="bandeauAlternate">
          <h4>{theme.name}</h4>
        </div>
      </div>
    );
  }
}
