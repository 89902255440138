import React, { Component } from "react";
import PropTypes from "prop-types";

import { Input, Checkbox } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import env from "../../config/env";
const { STATIC_URL } = env;
export default class SignupInformations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      password_check: null,
      check: false,
    };
  }

  static propTypes = {
    handleStep1: PropTypes.func.isRequired,
  };

  static defaultProps = {
    handleStep1: () => {},
  };

  changeFirstName = (first_name) => {
    if (first_name === "") {
      this.setState({ first_name: null });
    }
    this.setState({ first_name });
  };

  changeLastName = (last_name) => {
    if (last_name === "") {
      this.setState({ last_name: null });
    }
    this.setState({ last_name });
  };

  changeEmail = (email) => {
    if (email === "") {
      this.setState({ email: null });
    }
    this.setState({ email });
  };

  changePassword = (password) => {
    if (password === "") {
      this.setState({ password: null });
    }
    this.setState({ password });
  };

  changePasswordCheck = (password_check) => {
    if (password_check === "") {
      this.setState({ password_check: null });
    }
    this.setState({ password_check });
  };

  changeCheck = (check) => {
    this.setState({ check });
  };

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (this.validatePasswordCheck() !== null) return false;
    this.props.handleStep1({
      email: this.state.email.toLowerCase().trim(),
      password: this.state.password,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
    });
  };

  validateEmail = () => {
    if (this.state.email === null) {
      return null;
    } else if (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      return null;
    } else {
      return <p className="formatError">Format email incorrect</p>;
    }
  };

  validatePasswordCheck = () => {
    if (this.state.password === null) {
      return null;
    } else if (this.state.password === this.state.password_check) {
      return null;
    } else {
      return (
        <p className="formatError">Les mots de passe ne sont pas identiques</p>
      );
    }
  };

  validatePassword = () => {
    const { password } = this.state;
    if (password === null) {
      return true;
    } else if (!/^.{8,}$/.test(password)) {
      return true;
    } else if (!/^.*\d+.*$/.test(password)) {
      return true;
    } else if (!/^.*[A-Z]+.*$/.test(password)) {
      return true;
    }
    return false;
  };

  eightCharCheck = (password) => {
    if (/^.{8,}$/.test(password)) {
      return (
        <p className="done">
          <CheckOutlined /> 8 caractères minimum
        </p>
      );
    }
    return <p>8 caractères minimum</p>;
  };

  oneNumberCheck = (password) => {
    if (/^.*\d+.*$/.test(password)) {
      return (
        <p className="done">
          <CheckOutlined /> 1 chiffre
        </p>
      );
    }
    return <p>1 chiffre</p>;
  };

  oneMajCheck = (password) => {
    if (/^.*[A-Z]+.*$/.test(password)) {
      return (
        <p className="done">
          <CheckOutlined /> 1 majuscule
        </p>
      );
    }
    return <p>1 majuscule</p>;
  };

  render() {
    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div className="multiple-input">
          <div className="div-input">
            <label>Quel est votre prénom ?</label>
            <Input
              placeholder=""
              name="first_name"
              value={this.state.first_name}
              onChange={(e) => this.changeFirstName(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Et votre nom ?</label>
            <Input
              placeholder=""
              name="last_name"
              value={this.state.last_name}
              onChange={(e) => this.changeLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="div-input long">
          <label>
            Avec quelle adresse email souhaiteriez-vous vous identifier ?
          </label>
          <Input
            placeholder=""
            name="email"
            value={this.state.email}
            onChange={(e) => this.changeEmail(e.target.value)}
          />
          {this.validateEmail()}
        </div>
        <div className="multiple-input">
          <div className="div-input">
            <label>Choisissez un mot de passe</label>
            <Input.Password
              placeholder="Mot de passe"
              name="password"
              value={this.state.password}
              onChange={(e) => this.changePassword(e.target.value)}
            />
          </div>
          <div className="div-input">
            <div className="password-checker">
              {this.eightCharCheck(this.state.password)}
              {this.oneNumberCheck(this.state.password)}
              {this.oneMajCheck(this.state.password)}
            </div>
          </div>
        </div>
        <div className="multiple-input">
          {this.state.password !== null ? (
            <div className="div-input">
              <label>Confirmez votre mot de passe</label>
              <Input.Password
                placeholder="Confirmation du mot de passe"
                name="password_check"
                value={this.state.password_check}
                onChange={(e) => this.changePasswordCheck(e.target.value)}
              />
            </div>
          ) : null}
          <div className="div-input">{this.validatePasswordCheck()}</div>
        </div>
        <div className="div-input long">
          <Checkbox onChange={(e) => this.changeCheck(e.target.checked)}>
            En cochant cette case, j'accepte les{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${STATIC_URL}/cgu`}
              style={{ fontWeight: "600", color: "#2b8bbc" }}
            >
              conditions générales
            </a>{" "}
            de Wisdana
          </Checkbox>
        </div>
        <div className="submit">
          <button
            type="submit"
            disabled={
              this.state.check === false ||
              this.state.email === null ||
              this.state.first_name === null ||
              this.state.last_name === null ||
              this.validatePassword() ||
              this.state.password_check !== this.state.password
                ? true
                : false
            }
          >
            Suivant
          </button>
        </div>
      </form>
    );
  }
}
