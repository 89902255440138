import {
	AUTH_ACTIVATION,
} from './types';

const initialState = {
  status: false,
  error: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case AUTH_ACTIVATION:
			return {...state, error: action.error, status: action.status}
		default:
			return { ...state };
	}
}
