export function getPercent(percent) {
  if (percent.total === 0) {
    return 0;
  }

  if (percent.quantity_available === 0 || percent.quantity_available < 0) {
    return 100;
  }

  const quantity_bought = percent.total - percent.quantity_available;
  if (quantity_bought/percent.total * 100 > 99 && quantity_bought/percent.total * 100 < 100) {
    return 99;
  }

  return Math.ceil(quantity_bought/percent.total * 100);
};
