import React, { Component } from "react";
import PropTypes from "prop-types";

import ProductCard from "../elements/productCard";

import { trackWindowScroll } from "react-lazy-load-image-component";

class Products extends Component {
  static propTypes = {
    collecte: PropTypes.shape({
      association: PropTypes.object.isRequired,
      date_debut: PropTypes.string.isRequired,
      date_fin: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      distance: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.bool.isRequired,
      ]),
      id: PropTypes.number.isRequired,
      lieu_de_livraison: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      percent: PropTypes.object.isRequired,
      photo: PropTypes.string.isRequired,
      sous_theme: PropTypes.object,
      terminer: PropTypes.bool.isRequired,
      promue: PropTypes.bool.isRequired,
      theme: PropTypes.object.isRequired,
    }),
    color: PropTypes.string.isRequired,
    items: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    addToCart: PropTypes.func.isRequired,
    openCart: PropTypes.func.isRequired,
  };

  static defaultProps = {
    collecte: null,
    color: "",
    items: null,
    scrollPosition: {},
    addToCart: () => {},
    openCart: () => {},
  };

  addToCart = (data, collecte) => {
    // this.props.openCart();
    data.collecte = collecte;
    this.props.addToCart(data);
  };

  displayProducts = (items, color, scrollPosition, collecte) => {
    return items.map((x) => {
      return (
        <ProductCard
          key={x.id}
          addToCart={(data) => this.addToCart(data, collecte)}
          data={x}
          color={color}
          scrollPosition={scrollPosition}
          terminer={collecte.terminer}
          association={collecte.association}
        />
      );
    });
  };

  parseProducts = (items, color, scrollPosition, collecte) => {
    if (items === null) {
      return [
        <div key={0} className="collecte skeleton"></div>,
        <div key={1} className="collecte skeleton"></div>,
        <div key={2} className="collecte skeleton"></div>,
      ];
    }

    const items_1 = items
      .filter((x) => x.quantity_available !== 0 && x.quantity_available > 0)
      .sort((a, b) => a.price - b.price);
    const items_2 = items
      .filter((x) => x.quantity_available === 0 || x.quantity_available < 0)
      .sort((a, b) => a.price - b.price);

    return (
      <div className="products-box">
        {this.displayProducts(items_1, color, scrollPosition, collecte)}
        {this.displayProducts(items_2, color, scrollPosition, collecte)}
      </div>
    );
  };

  render() {
    const { collecte, color, items, scrollPosition } = this.props;

    return this.parseProducts(items, color, scrollPosition, collecte);
  }
}

export default trackWindowScroll(Products);
