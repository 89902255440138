import React, { Component } from "react";
import PropTypes from "prop-types";

import OrderCard from "./order";
import Products from "./products";

import { Drawer } from "antd";

class Orders extends Component {
  static propTypes = {
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        collecte: PropTypes.object.isRequired,
        customer: PropTypes.number.isRequired,
        delivered: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
        items: PropTypes.array.isRequired,
        order_date: PropTypes.string.isRequired,
        payment_intent: PropTypes.string.isRequired,
        successfull: PropTypes.bool.isRequired,
      }).isRequired
    ),
    getRecuFiscalLink: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    recu_fiscal: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    cleanRecuFiscal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    orders: [],
    getRecuFiscalLink: () => {},
    user: {},
    recu_fiscal: null,
    cleanRecuFiscal: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      tous: 0,
      selected: 0,
    };
  }

  handleTous = (tous) => {
    this.setState({ tous });
  };

  handleSelected = (selected) => {
    if (parseInt(selected) !== 0 && this.state.selected !== 0) {
      this.setState({ selected: 0 });
    } else {
      this.setState({ selected });
    }
  };

  getTransformedData = (user, recu_fiscal, orders, tous) => {
    const sorted_orders = this.sort(orders, tous);

    return sorted_orders.map((x) => {
      const object = x;

      return (
        <OrderCard
          key={x.id}
          data={object}
          user={user}
          color={object.collecte.theme.couleur}
          handleSelected={(selected) => this.handleSelected(selected)}
          getRecuFiscalLink={(id) => this.props.getRecuFiscalLink(id)}
          recu_fiscal={recu_fiscal}
          cleanRecuFiscal={() => this.props.cleanRecuFiscal()}
        />
      );
    });
  };

  sort = (arr, tous) => {
    switch (tous) {
      case 0:
        return arr;
      case 1:
        return arr.filter((x) => x.collecte.terminer === false);
      case 2:
        return arr.filter((x) => x.collecte.terminer === true);
      default:
        return arr;
    }
  };

  renderFiltres = (tous) => {
    const arr = [
      {
        key: 0,
        value: "Toutes",
      },
      {
        key: 1,
        value: "En cours",
      },
      {
        key: 2,
        value: "Terminées",
      },
    ];

    return arr.map((x) => {
      return (
        <p
          key={x.key}
          className={
            x.key === tous ? "association-filtre active" : "association-filtre"
          }
          onClick={() => this.handleTous(x.key)}
        >
          {x.value}
        </p>
      );
    });
  };

  render() {
    const { orders, user, recu_fiscal } = this.props;
    const { tous, selected } = this.state;

    return (
      <div className="association-collecte">
        {/* <div className="association-filtre-block">
          {this.renderFiltres(tous)}
        </div> */}
        <div className="orders-collecte-block">
          {this.getTransformedData(user, recu_fiscal, orders, tous)}
          {selected !== 0 && (
            <Drawer
              title={`Don #${orders.find((x) => x.id === selected).id}`}
              placement="right"
              closable={true}
              onClose={() => this.handleSelected(0)}
              visible={selected !== 0}
              className="cart-drawer"
              headerStyle={{ textAlign: "center" }}
            >
              <Products orders={orders.find((x) => x.id === selected)} />
            </Drawer>
          )}
        </div>
      </div>
    );
  }
}

export default Orders;
