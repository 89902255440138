import React from "react";

import Logo from "../../resources/img/logo_white.svg";
import MAINTENANCE_IMAGE from "../../resources/img/pet_on_screen.svg";

import env from "../../config/env";

const { ASSOCIATION_URL } = env;

const Maintenance = () => {
  return (
    <div
      className="static-header"
      style={{ background: "rgb(232, 222, 222)", height: "100vh" }}
    >
      <div style={{ display: "flex" }}>
        <div className="header">
          <div className="item">
            <div className="first">
              <a href="##">
                <img src={Logo} alt="Wisdana" />
              </a>
            </div>
          </div>
        </div>
        <div className="header">
          <div className="item">
            <div className="second">
              <a href={`${ASSOCIATION_URL}/association/login`}>
                <div className="association" style={{ cursor: "pointer" }}>
                  Espace associations
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="secondary-menu">
          <h1 style={{ fontWeight: 300, padding: "10px 15px" }}>
            Wisdana est en maintenance.
          </h1>
          <h2 style={{ fontWeight: 200 }}>On revient bientôt !</h2>
          <img
            src={MAINTENANCE_IMAGE}
            style={{ width: "80%", marginTop: 20 }}
            alt="maintenance"
          />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
