import React, { Component } from "react";
import PropTypes from "prop-types";

export default class IndividualItem extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  render() {
    const { data } = this.props;

    return (
      <div className="cart-item-checkout">
        <img src={data.article.photo} alt="Produit" />
        <p style={{ marginRight: 20, width: 150 }}>{data.article.nom}</p>
        <div className="cart-item-prices">
          <p className="special">
            {data.quantity} x €{data.price}
          </p>
          <p className="total">
            €{parseFloat(data.quantity * data.price).toFixed(2)}
          </p>
        </div>
      </div>
    );
  }
}
