import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, Select } from 'antd';

import { notification } from 'antd';

const { Option } = Select;

export default class Informations extends Component {
  static propTypes = {
    user: PropTypes.shape({
      user: PropTypes.exact({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }).isRequired,
      code_postal: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
      ville: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
      pays: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
      adresse: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
      stripe_customer: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
    }).isRequired,
    handleNewInformations: PropTypes.func.isRequired,
  }

  static defaultProps = {
    user: {},
    handleNewInformations: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      first_name: null,
      last_name: null,
      ville: null,
      code_postal: null,
      adresse: null,
      societe: null,
      pays: 'FR'
    }
  }

  componentDidMount() {
    const { user } = this.props;

    this.setState({
      first_name: user.user.first_name,
      last_name: user.user.last_name,
      ville: user.ville,
      code_postal: user.code_postal,
      adresse: user.adresse,
      pays: user.pays !== null ? user.pays:"FR",
      societe: user.societe,
    });
  }

  changeFirstName = (first_name) => {
    this.setState({ first_name });
  }

  changeLastName = (last_name) => {
    this.setState({ last_name });
  }

  changeVille = (ville) => {
    this.setState({ ville });
  }

  changeAdresse = (adresse) => {
    this.setState({ adresse });
  }

  changeCodePostal = (code_postal) => {
    this.setState({ code_postal });
  }

  changePays = (pays) => {
    this.setState({ pays });
  }

  changeSociete = (societe) => {
    this.setState({ societe });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (this.validateCodePostal() !== null) return false;
    if (this.validateFirstAndLastName() !== null) return false;
    this.props.handleNewInformations(this.state);
  }

  validateFirstAndLastName = () => {
    if (this.state.first_name !== null && this.state.last_name !== null) {
      return null;
    }
    return false;
  }

  validateCodePostal = () => {
    if (this.state.code_postal === null) {
      return null;
    } else if (/^\d{5}$/.test(this.state.code_postal)) {
      return null;
    } else {
      return <p className="formatError">Format: 00000</p>;
    }
  }

  renderCountries = (country) => {
    const countries = [
      {
        value: "UK",
        name: "Angleterre",
      },
      {
        value: "AT",
        name: "Autriche",
      },
      {
        value: "BE",
        name: "Belgique",
      },
      {
        value: "US",
        name: "États Unis",
      },
      {
        value: "CA",
        name: "Canada",
      },
      {
        value: "CN",
        name: "Chine",
      },
      {
        value: "FR",
        name: "France",
      },
      {
        value: "DE",
        name: "Allemagne",
      },
      {
        value: "IT",
        name: "Italie",
      },
      {
        value: "NL",
        name: "Hollande",
      },
      {
        value: "PT",
        name: "Portugal",
      },
      {
        value: "ES",
        name: "Espagne",
      },
      {
        value: "CH",
        name: "Suisse",
      },
    ];
    return (
      <Select
        onChange={(event) => this.changePays(event)}
        value={country}
        bordered={false}
        style={{ width: '100%' }}
      >
        {countries.map((x, index) => {
          return <Option key={index} value={x.value}>{x.name}</Option>;
        })}
      </Select>
    );
  }

  render() {
    const { first_name, last_name, ville, code_postal, adresse, pays, societe } = this.state;
    const { success, user, error } = this.props;

    if (success) {
      setTimeout(this.props.flushSuccess(), 1000);
    }

    return (
      <div className="informations-container">
        {success &&
          notification['success']({
            message: 'Mise à jour de vos informations',
            description: `Merci d'avoir mis à jour tes informations ${user.user.first_name} !`,
            duration: 1,
            placement: 'topRight'
          })
        }
        <div className="informations-block">
          <h3 className="title">
            Mes informations
          </h3>
          <form onSubmit={e => this.handleSubmit(e)}>
            <div className="multiple-input">
              <div className="div-input">
                <label>Prénom</label>
                <Input
                  placeholder=""
                  name="first_name"
                  value={first_name}
                  onChange={(e) => this.changeFirstName(e.target.value)}
                />
              </div>
              <div className="div-input">
                <label>Nom de famille</label>
                <Input
                  placeholder=""
                  name="last_name"
                  value={last_name}
                  onChange={(e) => this.changeLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="div-input long">
              <label>Société <span style={{ fontStyle: 'italic' }}>(optionnel)</span></label>
              <Input
                placeholder=""
                name="societe"
                value={societe}
                onChange={(e) => this.changeSociete(e.target.value)}
              />
            </div>
            <div className="div-input long">
              <label>Adresse</label>
              <Input
                placeholder=""
                name="adresse"
                value={adresse}
                onChange={(e) => this.changeAdresse(e.target.value)}
              />
            </div>
            <div className="multiple-input">
              <div className="div-input">
                <label>Code postal</label>
                <Input
                  placeholder=""
                  name="code_postal"
                  value={code_postal}
                  onChange={(e) => this.changeCodePostal(e.target.value)}
                />
                {this.validateCodePostal()}
              </div>
              <div className="div-input">
                <label>Ville</label>
                <Input
                  placeholder=""
                  name="ville"
                  value={ville}
                  onChange={(e) => this.changeVille(e.target.value)}
                />
              </div>
            </div>
            <div className="div-input long">
              <label>Pays</label>
              <div className="ant-input" style={{ display: 'flex', alignItems: 'center' }}>
                <div id="country" className={`${pays.toLowerCase()}`} style={{ height: 16 }}>
                </div>
                {this.renderCountries(pays)}
              </div>
            </div>
            {error !== null &&
              <div className="errorDiv">
                <p>{error}</p>
              </div>
            }
            <div className="submit">
              <button type="submit">Mettre à jour</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
