import {
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM,
  RESET_PASSWORD_DONE,
} from './types';

const initialState = {
  message: null,
  error: null,
  status: null,
  confirmed: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case RESET_PASSWORD:
			return { ...state, status: action.status };
    case RESET_PASSWORD_FAIL:
			return { ...state, error: action.error };
		case RESET_PASSWORD_CONFIRM:
			return { ...state, status: action.status, error: action.error, confirmed: action.confirmed };
		case RESET_PASSWORD_DONE:
			return { ...state, message: action.message };
    default:
      return { ...state };
	}
}
