import React, { Component } from "react";
import PropTypes from "prop-types";

import Image from "./image";

// import { EnvironmentOutlined } from '@ant-design/icons';

export default class CollecteCard extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    scrollPosition: PropTypes.object,
  };

  static defaultProps = {
    data: {},
    color: "",
    scrollPosition: {},
  };

  truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  render() {
    const { data, color, scrollPosition } = this.props;

    return (
      <div className="collecte" style={{ borderColor: color }}>
        <a href={`/app/association/${data.id}`} style={{ color: "inherit" }}>
          <div className="collecte-header">
            <div className="collecte-logo">
              {data.logo ? (
                <div style={{ backgroundImage: `url('${data.logo}')` }}></div>
              ) : null}
            </div>
            <div className="collecte-subHeader-block">
              <p
                style={{ color: color, fontWeight: 600, marginBottom: "0.5em" }}
              >
                {data.nom}
              </p>
              {/*<div className="collecte-subHeader">
                {data.distance !== false && <span><EnvironmentOutlined />{`${data.distance}km`}</span>}
              </div>*/}
            </div>
          </div>
          <div className="image">
            <Image
              scrollPosition={scrollPosition}
              image={{
                alt: data.nom,
                height: 200,
                src: data.photo,
              }}
            />
          </div>
          <div className="text">
            <p className="paragraph">{this.truncate(data.description, 100)}</p>
          </div>
        </a>
      </div>
    );
  }
}
