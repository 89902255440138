import React, { Component } from "react";
import PropTypes from "prop-types";

import { getPercent } from "./percent_calculation";
import Image from "./image";
// import LazyBackground from '../elements/lazybackground';
import Tracker from "./tracker";

// import { FieldTimeOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { FieldTimeOutlined } from "@ant-design/icons";

export default class CollecteCard extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    scrollPosition: PropTypes.object,
  };

  dateFin = (date_fin, terminer) => {
    if (terminer === true) {
      return "Terminée";
    }
    const now = new Date();
    const difference_in_time = new Date(date_fin).getTime() - now.getTime();
    const days = difference_in_time / (1000 * 3600 * 24);
    if (Math.round(days) > 0) {
      return `Plus que ${Math.ceil(days)} jours`;
    } else if (Math.round(days) === 0) {
      return "Aujourd'hui";
    }
    return "Terminée";
  };

  truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  render() {
    const { data, color, scrollPosition } = this.props;

    return (
      <div className="collecte" style={{ borderColor: color }}>
        <a href={`/app/collecte/${data.code_url}`} style={{ color: "inherit" }}>
          <div className="collecte-header">
            <div className="collecte-logo">
              {data.association.logo ? (
                <div
                  style={{ backgroundImage: `url('${data.association.logo}')` }}
                ></div>
              ) : null}
            </div>
            <div className="collecte-subHeader-block">
              <p
                style={{
                  color: color,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  height: 45,
                }}
              >
                {this.truncate(data.association.nom, 37)}
              </p>
              <div className="collecte-subHeader">
                <span>
                  <FieldTimeOutlined />
                  {data.date_fin === null
                    ? "collecte permanente"
                    : this.dateFin(data.date_fin, data.terminer)}
                </span>
                {/*data.distance !== false && <span><EnvironmentOutlined />{`${data.distance}km`}</span>*/}
              </div>
            </div>
          </div>
          <div className="image">
            <Image
              scrollPosition={scrollPosition}
              image={{
                alt: data.nom,
                height: 200,
                src: data.photo,
              }}
            />
          </div>
          <div className="text">
            <p className="titre">{data.nom}</p>
            <p className="paragraph">{this.truncate(data.description, 100)}</p>
            {data.percent.total !== 0 && (
              <Tracker
                color={color}
                percent={getPercent(data.percent)}
                height={4}
                radius={2}
              />
            )}
            {data.percent.total !== 0 && (
              <p className="tracker-text">
                <span style={{ color: color }}>
                  {getPercent(data.percent)}%
                </span>{" "}
                des produits déjà donnés
              </p>
            )}
          </div>
        </a>
      </div>
    );
  }
}
