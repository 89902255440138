import React, { Component } from "react";
import PropTypes from "prop-types";

export default class UserDataForm extends Component {
  static propTypes = {
    userData: PropTypes.object.isRequired,
  };

  static defaultProps = {
    userData: {},
  };

  renderCountries = (userData) => {
    const countries = [
      {
        value: "UK",
        name: "Angleterre",
      },
      {
        value: "AT",
        name: "Autriche",
      },
      {
        value: "BE",
        name: "Belgique",
      },
      {
        value: "US",
        name: "États Unis",
      },
      {
        value: "CA",
        name: "Canada",
      },
      {
        value: "CN",
        name: "Chine",
      },
      {
        value: "FR",
        name: "France",
      },
      {
        value: "DE",
        name: "Allemagne",
      },
      {
        value: "IT",
        name: "Italie",
      },
      {
        value: "NL",
        name: "Hollande",
      },
      {
        value: "PT",
        name: "Portugal",
      },
      {
        value: "ES",
        name: "Espagne",
      },
      {
        value: "CH",
        name: "Suisse",
      },
    ];

    return (
      <select
        name="country"
        onChange={(event) => this.props.handleCountry(event.target.value)}
        value={userData.country}
      >
        {countries.map((x, index) => {
          return (
            <option key={index} value={x.value}>
              {x.name}
            </option>
          );
        })}
      </select>
    );
  };

  render() {
    const { userData } = this.props;

    if (userData.country) {
      return (
        <section>
          <h2>Adresse de facturation</h2>
          <fieldset>
            <label>
              <span>Prénom</span>
              <input
                name="first_name"
                className="field"
                placeholder="Prénom"
                value={userData.first_name}
                required
                onChange={(event) =>
                  this.props.handleFirstName(event.target.value)
                }
              />
            </label>
            <label>
              <span>Nom</span>
              <input
                name="last_name"
                className="field"
                placeholder="Nom"
                value={userData.last_name}
                required
                onChange={(event) =>
                  this.props.handleLastName(event.target.value)
                }
              />
            </label>
            <label>
              <span>Email</span>
              <input
                name="email"
                className="field"
                placeholder="Email"
                type="email"
                value={userData.email}
                required
                onChange={(event) =>
                  this.props.handleEmail(
                    event.target.value.toLowerCase().trim()
                  )
                }
              />
            </label>
            <label>
              <span>Adresse</span>
              <input
                name="address"
                className="field"
                placeholder="Adresse"
                value={userData.adress}
                required
                onChange={(event) =>
                  this.props.handleAdress(event.target.value)
                }
              />
            </label>
            <label>
              <span>Ville</span>
              <input
                name="city"
                className="field"
                placeholder="Ville"
                value={userData.city}
                required
                onChange={(event) => this.props.handleCity(event.target.value)}
              />
            </label>
            <label>
              <span>Code postal</span>
              <input
                name="postal_code"
                className="field"
                placeholder="Code postal"
                value={userData.postalCode}
                required
                onChange={(event) =>
                  this.props.handlePostalCode(event.target.value)
                }
              />
            </label>
            <label className="select">
              <span>Pays</span>
              <div
                id="country"
                className={`field ${userData.country.toLowerCase()}`}
              >
                {this.renderCountries(userData)}
              </div>
            </label>
          </fieldset>
        </section>
      );
    }
    return null;
  }
}
