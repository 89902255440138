import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

export default class Menu extends Component {
  static propTypes = {
    activeMenu: PropTypes.number.isRequired,
    handleActiveMenu: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeMenu: 0,
    handleActiveMenu: () => {},
  }

  renderMenu = (activeMenu) => {
    const array = [{
      key: 0,
      value: "Mes dons"
    },{
      key: 1,
      value: "Mes informations"
    },{
      key: 2,
      value: "Mot de passe"
    }];

    return array.map(x => {
      return (
        <p
          key={x.key}
          className={activeMenu === x.key ? "dashboard-menu-item active": "dashboard-menu-item"}
          onClick={() => this.props.handleActiveMenu(x.key)}
        >
          {x.value}
        </p>
      );
    });
  }

  render() {
    const { activeMenu } = this.props;

    return (
      <div className="dashboard-menu-container">
        {this.renderMenu(activeMenu)}
        <Link to={'/app/signout'} className="dashboard-menu-item desktop" style={{ color: 'inherit' }}>
          Se déconnecter
        </Link>
      </div>
    );
  }
}
