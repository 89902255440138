import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tracker from '../elements/tracker';

import SignupInformations from './signup_informations';
import SignupAdresse from './signup_adresse';

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      first_name: null,
      user_data: null,
    }
  }

  static propTypes = {
    signup: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    error: null,
    signup: () => {},
  };

  handleStep1 = (user_data) => {
    this.setState({ user_data, first_name: user_data.first_name });
    this.setState({ step: 2 });
  }

  handleSubmit = (data) => {
    const { user_data } = this.state;

    this.props.signup({
      user: user_data,
      location: '/app/feed',
      ville: data.ville,
      code_postal: data.code_postal,
      adresse: data.adresse,
      country: data.pays,
    });
  }

  renderStep1 = () => {
    return [
      <h2 key={1} className="title">
        Inscrivez-vous pour avoir accès à toutes les fonctionnalités
      </h2>,
      <p key={2} className="subtitle">
        Promis, cela ne sera pas long !
      </p>
    ];
  }

  renderStep2 = () => {
    const { first_name } = this.state;
    return [
      <h2 key={3} className="title">
        Enchanté {first_name}, <br />l'inscription est presque terminée
      </h2>,
      <p key={4} className="subtitle">
        Juste une adresse pour économiser sur les impôts
      </p>
    ];
  }

  render() {
    const { error } = this.props;
    const { step } = this.state;

    return (
      <div className="loginInputContainer">
        <div className="loginEtape">
          <Tracker color={"#2b8bbc"} percent={step === 1 ? 50:100} height={8} radius={4} />
          <p>
            Étape <span className="bold">{step}</span> sur 2
          </p>
        </div>
        {step === 1 ? this.renderStep1():this.renderStep2()}
        {step === 1 ?
          <SignupInformations
            handleStep1={(data) => this.handleStep1(data)}
          />
          :
          <SignupAdresse
            handleStep2={(data) => this.handleSubmit(data)}
          />
        }
        {error !== null &&
          <div className="errorDiv">
            <p>{error}</p>
          </div>
        }
      </div>
    );
  }
}
