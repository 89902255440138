import axios from 'axios';

import {
	AUTH_USER,
	AUTH_ERROR,
	UNAUTH_USER,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const LOGIN_URL = API_ROUTES.login;
const SIGNUP_URL = API_ROUTES.signup;

export function authUser({ username, password }) {
	return function(dispatch) {
		// Submit email and password
		const email = username.toLowerCase().trim();
		axios.post(`${LOGIN_URL}`, { username: email, password })
			.then(response => {
				// If request is good
				// - Save JWT token
				localStorage.setItem('wisdana-customer-token', response.data.token);
				// - Update state => authd
				dispatch({ type: AUTH_USER, authenticated: true, activation: false });
			})
			.catch((error) => {
				// If request is bad
				// - Show error	to user
				dispatch(authError(error.response.data.error));
			});
	}

}

export function signup(data) {
	return function(dispatch) {
		axios.post(`${SIGNUP_URL}`, data)
			.then(response => {
				// If request is good
				// - Save JWT token
				localStorage.setItem('wisdana-customer-token', response.data.token);
				// - Update state => authd
				dispatch({ type: AUTH_USER, authenticated: false, activation: response.data.activation });
			})
			.catch(error => {
				console.log(error.response.data);
				dispatch(authError(error.response.data.error));
			});
	}
}

export function refresh() {
	return function(dispatch) {
		dispatch({ type: AUTH_ERROR, error: null, verify: null });
	};
}

export function authError(error) {
	return {
		type: AUTH_ERROR,
		error: error,
		verify: null,
	};
}

export function signoutUser() {
	localStorage.removeItem('wisdana-customer-token');

	return function(dispatch) {
		dispatch({ type: UNAUTH_USER });
	}
}
