import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckOutlined } from '@ant-design/icons';

import { Input } from 'antd';

class Password extends Component {
  static propTypes = {
    createPassword: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
  }

  static defaultProps = {
    createPassword: () => {},
    email: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      password: null,
      email: null,
    }
  }

  componentDidMount() {
    this.setState({ email: this.props.email });
  }

  changeEmail = (email) => {
    this.setState({ email });
  }

  changePassword = (password) => {
    if (password === '') {
      this.setState({ password: null });
    }
    this.setState({ password });
  }

  validatePassword = () => {
    const { password, email } = this.state;
    if (email === null) {
      return true;
    }
    if (password === null) {
      return true;
    } else if (!/^.{8,}$/.test(password)) {
      return true;
    } else if(!(/^.*\d+.*$/.test(password))) {
      return true;
    } else if(!/^.*[A-Z]+.*$/.test(password)) {
      return true;
    }
    return false;
  }

  eightCharCheck = (password) => {
    if (/^.{8,}$/.test(password)) {
      return <p className="done"><CheckOutlined />  8 caractères minimum</p>;
    }
    return <p>8 caractères minimum</p>;
  }

  oneNumberCheck = (password) => {
    if (/^.*\d+.*$/.test(password)) {
      return <p className="done"><CheckOutlined />  1 chiffre</p>;
    }
    return <p>1 chiffre</p>;
  }

  oneMajCheck = (password) => {
    if (/^.*[A-Z]+.*$/.test(password)) {
      return <p className="done"><CheckOutlined />  1 majuscule</p>;
    }
    return <p>1 majuscule</p>;
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.createPassword({ email: this.state.email, password: this.state.password });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="div-input long">
          <label>Email</label>
          <Input
            placeholder="Email"
            name="email"
            value={this.state.email}
            onChange={(e) => this.changeEmail(e.target.value)}
          />
        </div>
        <div className="multiple-input">
          <div className="div-input">
            <label>Choisissez un mot de passe</label>
            <Input.Password
              placeholder="Mot de passe"
              name="password"
              value={this.state.password}
              onChange={(e) => this.changePassword(e.target.value)}
            />
          </div>
          <div className="div-input">
            <div className="password-checker">
              {this.eightCharCheck(this.state.password)}
              {this.oneNumberCheck(this.state.password)}
              {this.oneMajCheck(this.state.password)}
            </div>
          </div>
        </div>
        <div className="submit">
          <button type="submit" disabled={this.validatePassword()? true:false}>Valider</button>
        </div>
      </form>
    );
  }
}

export default Password
