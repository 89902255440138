import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

// import { HomeOutlined, ReadOutlined, UserOutlined } from "@ant-design/icons";

import env from "../../config/env";
const { ASSOCIATION_URL, CONTACT_EMAIL, STATIC_URL } = env;

export default class Footer extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
  };

  static defaultProps = {
    location: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  componentDidMount() {
    this.renderActive(this.props.location);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location !== nextProps.location) {
      this.renderActive(nextProps.location);
    }
    return true;
  }

  handleActive = (active) => {
    this.setState({ active });
  };

  renderActive = (location) => {
    switch (location) {
      case "/app/feed":
        this.handleActive(0);
        break;
      case "/app/dashboard":
        this.handleActive(3);
        break;
      // case "/app/comment-ca-marche":
      //   this.handleActive(1);
      //   break;
      default:
        this.handleActive(0);
        break;
    }
  };
  render() {
    // const { active } = this.state;

    return (
      <div className="footer-block">
        {/*<div className="footer-mobile">
          <div className={active === 0 ? "element active":"element"}>
            <Link to="/app/feed">
              <HomeOutlined style={{ fontSize: 20 }} />
            </Link>
          </div>
          <div className={active === 1 ? "element active":"element"}>
            <Link to="/app/comment-ca-marche">
              <ReadOutlined style={{ fontSize: 20 }} />
            </Link>
          </div>
          <div className={active === 3 ? "element active":"element"}>
            <Link to="/app/dashboard">
              <UserOutlined style={{ fontSize: 20 }} />
            </Link>
          </div>
        </div>*/}
        <div className="footer">
			<div className="container">
				<div className="row">
					<div className="col half social">
						<a
							className="social"
							href="https://www.facebook.com/profile.php?id=100085944267406"
							rel="noopener noreferrer"
						>
							<i className="fab fa-facebook-f"></i>
						</a>
						<a
							className="social"
							href="https://www.instagram.com/wisdana.officiel/"
							rel="noopener noreferrer"
						>
							<i className="fab fa-instagram"></i>
						</a>
						<a
							className="social"
							href={`mailto:${CONTACT_EMAIL}?subject=Question au sujet de Wisdana`}
						>
							<i className="fal fa-envelope"></i>
						</a>
					</div>
					<div className="col half">
						<div className="wrapper">
							<div className="col half-third">
								<h4>Recevez des produits</h4>
								<a
									className="link"
									href={`${STATIC_URL}/nos-prix-nos-produits`}
									rel="noopener noreferrer"
								>
									Produits disponibles
								</a>
								<a
									className="link"
									href={`${STATIC_URL}/programme-bonus`}
									rel="noopener noreferrer"
								>
									Bons d'achats offerts
								</a>
								<a
									className="link"
									href={`${STATIC_URL}/comparatif`}
									rel="noopener noreferrer"
								>
									Wisdana vs magasin
								</a>
								<a
									className="link"
									href={`${STATIC_URL}/lancer-une-collecte`}
									rel="noopener noreferrer"
								>
									Lancer une collecte
								</a>
							</div>
							<div className="col half-third">
								<h4>En savoir plus</h4>
								<a
									className="link"
									href={`${STATIC_URL}/comment-ca-marche`}
									rel="noopener noreferrer"
								>
									Comment ça marche ?
								</a>
								<a
									className="link"
									href={`${STATIC_URL}/faq-donateurs-index`}
									rel="noopener noreferrer"
								>
									FAQ Donateurs
								</a>
								<a
									className="link"
									href={`${STATIC_URL}/faq-associations-index`}
									rel="noopener noreferrer"
								>
									FAQ Associations
								</a>
							</div>
							<div className="col half-third mt">
								<h4>Nous connaître</h4>
								<a
									className="link"
									href={`${STATIC_URL}/qui-sommes-nous-`}
									rel="noopener noreferrer"
								>
									A propos
								</a>
								<a
									className="link"
									href={`${STATIC_URL}/contact`}
									rel="noopener noreferrer"
								>
									Nous contacter
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<hr/>
				</div>
				<div className="row">
					<div className="col half phone">
						<span className="phone">
							<i className="fas fa-fw fa-phone"></i>
							01 76 35 04 57
						</span>
					</div>
					<div className="col half">
						<div className="wrapper">
							<div className="col third">
								<a
									className="link small"
									href={`${STATIC_URL}/confidentialite`}
									rel="noopener noreferrer"
								>
									Confidentialité
								</a>
							</div>
							<div className="col third">
								<a
									className="link small"
									href={`${STATIC_URL}/cgu`}
									rel="noopener noreferrer"
								>
									Conditions générales
								</a>
							</div>
							<div className="col third">
								<a
									className="link small"
									href={`${STATIC_URL}/paiements`}
									rel="noopener noreferrer"
								>
									Paiements sécurisés
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
        <div className="secondary-footer">
          <p>Tous droits réservés © Wisdana {new Date().getFullYear()}</p>
        </div>
      </div>
    );
  }
}
