import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderBottomBG from '../../resources/img/header_bottom_bg.png';

export default class Themes extends Component {
  static propTypes = {
    themes: PropTypes.array.isRequired,
    theme: PropTypes.number.isRequired,
    changeTheme: PropTypes.func.isRequired,
  };

  static defaultProps = {
    themes: null,
    theme: null,
  };

  renderThemes = (themes) => {
    return themes.map(x => {
      if (x.id === this.props.theme) {
        return (
          <div key={x.id} onClick={() => this.props.changeTheme(x.id)} className="item">
            <img src={x.image_selected} alt={x.name} />
          </div>
        )
      }
      return (
        <div key={x.id} onClick={() => this.props.changeTheme(x.id)} className="item">
          <img src={x.image} alt={x.name} />
        </div>
      );
    });
  }

  render() {
    const { themes } = this.props;
    return (
      <div className="subheader" style={{ backgroundImage: `url(${HeaderBottomBG})` }}>
        <div className="textDiv">
          <h3> Hello, on t'attendait </h3>
          <h2> Les animaux ont besoin de toi ! </h2>
        </div>
        <div className="themesDiv">
          {/*this.renderThemes(themes)*/}
        </div>
      </div>
    );
  }
}
