import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";

import { resetPassword, confirmToken, resetPasswordDone } from "./actions";

import { parse } from "query-string";

import ResetPasswordForm from "../../components/reset_password/resetPasswordForm";
import ResetPasswordDone from "../../components/reset_password/resetPasswordDone";

import logo from "../../resources/img/logo_white.svg";
import HeaderBG from "../../resources/img/header_bg.png";

// import custom css
import "./resetPassword.scss";

import env from "../../config/env";
const { ASSOCIATION_URL } = env;

class ResetPasswordContainer extends Component {
  static propTypes = {
    message: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    status: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    confirmed: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    message: null,
    error: null,
    status: null,
    confirmed: null,
  };

  componentDidMount() {
    const query = parse(window.location.search);

    if (query.token && query.id) {
      this.props.confirmToken({ userId: query.id, token: query.token });
    }
  }

  render() {
    const { error, status, message, confirmed } = this.props;

    return (
      <div
        className="loginContainer"
        style={{ backgroundImage: `url(${HeaderBG})` }}
      >
        <div className="loginContainerBox">
          <img src={logo} className="logo" alt="logo" />
		  {/*
          <p className="association-question">
            Vous êtes une association ?{" "}
            <a href={`${ASSOCIATION_URL}/association/login`}>Cliquez ici</a>
          </p>
          */}
          <div className="loginSignupBox">
            {confirmed === null && (
              <ResetPasswordForm
                resetPassword={(email) => this.props.resetPassword(email)}
                error={error}
                status={status}
              />
            )}
            {confirmed !== null && (
              <ResetPasswordDone
                resetPasswordDone={(password) =>
                  this.props.resetPasswordDone(password)
                }
                error={error}
                message={message}
              />
            )}
            <p className="retour-au-site">
              <Link to="/app/feed">Retour au site</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.reset_password.message,
    error: state.reset_password.error,
    status: state.reset_password.status,
    confirmed: state.reset_password.confirmed,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword,
      confirmToken,
      resetPasswordDone,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
