import React from 'react';

import { Skeleton } from 'antd';

export default () => (
  <div style={{ minHeight: '100vh' }}>
    <div className="collecteBlockDiv">
      <div className="collecteDiv">
        <div className="collectBox">
          <div className="skeleton-blink">
            <div className="feedBlock">
            <div className="collecte skeleton">
              <div className="image"></div>
              <div className="text">
                <Skeleton active />
              </div>
            </div>
              <div className="collecte skeleton">
                <div className="image"></div>
                <div className="text">
                  <Skeleton active />
                </div>
              </div>
              <div className="collecte skeleton">
                <div className="image"></div>
                <div className="text">
                  <Skeleton active />
                </div>
              </div>
              <div className="collecte skeleton">
                <div className="image"></div>
                <div className="text">
                  <Skeleton active />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
