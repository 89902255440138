import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getRankings,
  getOrders,
  patchNewPassword,
  updateUser,
  flushSuccess,
  getRecuFiscalLink,
  cleanRecuFiscal,
} from "./actions";

import Collecte from "../../components/dashboard/collecte";
import Informations from "../../components/dashboard/informations";
import DashboardMenu from "../../components/dashboard/menu";
import MotDePasse from "../../components/dashboard/mot_de_passe";

import "../../components/dashboard/style.scss";
import "./style.scss";

class DashboardContainer extends Component {
  static propTypes = {
    user: PropTypes.shape({
      user: PropTypes.exact({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }).isRequired,
      code_postal: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
      ville: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
      adresse: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
      stripe_customer: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.oneOf([null]).isRequired,
      ]),
    }).isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    rankings: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    recu_fiscal: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    user: {},
    rankings: null,
    error: null,
    recu_fiscal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeMenu: 0,
    };
  }

  componentDidMount() {
    this.props.getRankings();
    this.props.getOrders();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recu_fiscal === null && this.props.recu_fiscal !== null) {
      window.open(this.props.recu_fiscal.link, "_blank");
    }
  }

  handleActiveMenu = (activeMenu) => {
    this.setState({ activeMenu });
  };

  renderActive = (activeMenu) => {
    const { user, error, rankings, orders, success, recu_fiscal } = this.props;
    switch (activeMenu) {
      case 0:
        return (
          <Collecte
            rankings={rankings}
            orders={orders}
            getRecuFiscalLink={(id) => this.props.getRecuFiscalLink(id)}
            user={user}
            recu_fiscal={recu_fiscal}
            cleanRecuFiscal={() => this.props.cleanRecuFiscal()}
          />
        );
      case 1:
        return (
          <Informations
            user={user}
            handleNewInformations={(data) => this.props.updateUser(data)}
            error={error}
            flushSuccess={() => this.props.flushSuccess()}
            success={success}
          />
        );
      case 2:
        return (
          <MotDePasse
            handleNewPassword={(data) => this.props.patchNewPassword(data)}
            error={error}
            flushSuccess={() => this.props.flushSuccess()}
            success={success}
          />
        );
      default:
        return <Collecte rankings={rankings} orders={orders} />;
    }
  };

  render() {
    const { activeMenu } = this.state;

    return (
      <div className="dashboard-container">
        <DashboardMenu
          activeMenu={activeMenu}
          handleActiveMenu={(activeMenu) => this.handleActiveMenu(activeMenu)}
        />
        <div className="dashboard-active">{this.renderActive(activeMenu)}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    rankings: state.dashboard.rankings,
    error: state.dashboard.error,
    orders: state.dashboard.orders,
    success: state.dashboard.success,
    recu_fiscal: state.dashboard.recu_fiscal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRankings,
      getOrders,
      patchNewPassword,
      updateUser,
      flushSuccess,
      getRecuFiscalLink,
      cleanRecuFiscal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
