import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { Modal, message, Tooltip } from "antd";

let hide = null;

export default class Order extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
    handleSelected: PropTypes.func.isRequired,
    getRecuFiscalLink: PropTypes.func.isRequired,
    recu_fiscal: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    cleanRecuFiscal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
    color: "",
    handleSelected: () => {},
    getRecuFiscalLink: () => {},
    recu_fiscal: null,
    cleanRecuFiscal: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      click_recu_fiscal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.recu_fiscal === null &&
      this.props.recu_fiscal !== null &&
      this.state.click_recu_fiscal === true
    ) {
      hide();
      this.setState({ click_recu_fiscal: false });
      this.props.cleanRecuFiscal();
    }
  }

  dateFin = (date_fin) => {
    const date = new Date(date_fin);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  getTotal = (data) => {
    const price = data.items.reduce((acc, val) => {
      acc += val.quantity * val.price;
      return acc;
    }, 0.0);
    return price.toFixed(2);
    // return (price + (data.frais_service / 100)).toFixed(2);
  };

  getRecuFiscalLink = (user, data, id) => {
    this.setState({ click_recu_fiscal: true });
    const { association } = data.collecte;

    if (data.delivered === false) {
      Modal.warning({
        title: "Problème avec le reçu fiscal",
        content:
          "L'association n'a pas encore reçu la commande. Le reçu fiscal sera disponible lorsque la commande aura été livrée.",
        onOk: () => {
          this.setState({ click_recu_fiscal: false });
        },
      });
      return;
    }

    if (
      user.adresse === null ||
      user.code_postal === null ||
      user.pays === null ||
      user.ville === null
    ) {
      Modal.warning({
        title: "Problème avec le reçu fiscal",
        content:
          "Vous n'avez entré aucune adresse, code postal, pays ou ville. Nous ne pouvons donc pas créer de reçu fiscal pour vous.",
        onOk: () => {
          this.setState({ click_recu_fiscal: false });
        },
      });
      return;
    }

    if (
      association.adresse === null ||
      association.code_postal === null ||
      association.pays === null ||
      association.ville === null ||
      association.reconnaissance === "Aucune" ||
      association.objet_structure === null ||
      association.signature === false ||
      association.identite_signataire === false ||
      association.fonction_signataire === false
    ) {
      Modal.warning({
        title: "Problème avec le reçu fiscal",
        content:
          "L'association n'a pas encore envoyé les informations nécessaires à l'émission d'un reçu fiscal.",
        onOk: () => {
          this.setState({ click_recu_fiscal: false });
        },
      });
      return;
    }

    hide = message.loading("Reçu fiscal en préparation", 0);

    this.props.getRecuFiscalLink(id);
  };

  truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  renderRecuFiscal = (recu_fiscal, user, data, id) => {
    const { click_recu_fiscal } = this.state;
    if (!data.receipt_edited) {
      return (
        <Tooltip
          className="recu-asso disabled"
          title="Le reçu fiscal n'est pas encore disponible."
        >
          reçu fiscal
        </Tooltip>
      );
    }
    if (data.collecte.association.defiscalisation_percent !== 0) {
      if (recu_fiscal === null && !click_recu_fiscal) {
        return (
          <p
            className="recu-asso"
            onClick={() => this.getRecuFiscalLink(user, data, id)}
          >
            reçu fiscal
          </p>
        );
      } else if (recu_fiscal === null && click_recu_fiscal) {
        return <p className="recu-asso">reçu fiscal</p>;
      }
    }
    return (
      <Tooltip title="L'association n'est pas éligible pour l'émission de reçus fiscaux">
        <p className="recu-asso disabled">reçu fiscal</p>
      </Tooltip>
    );
  };

  render() {
    const { data, color, user, recu_fiscal } = this.props;

    return (
      <div className="orders-block">
        <p className="nom-asso" style={{ color }}>
          {this.truncate(data.collecte.association.nom, 20)}
        </p>
        <p>
          <span
            onClick={() => this.props.handleSelected(data.id)}
            className="don-asso"
          >
            don #{data.id}
          </span>
        </p>
        <p>{this.dateFin(data.order_date)}</p>
        <p className="total-asso">{this.getTotal(data)}€</p>
        {this.renderRecuFiscal(recu_fiscal, user, data, data.id)}
        <div className="cta-asso">
          <Link
            to={`/app/collecte/${data.collecte.code_url}`}
            className="cta-p"
          >
            voir la collecte
          </Link>
        </div>
      </div>
    );
  }
}
