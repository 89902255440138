import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CollecteCard from '../elements/collecteCard';

import { trackWindowScroll }from 'react-lazy-load-image-component';

class Collecte extends Component {
  static propTypes = {
    collectes: PropTypes.array.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    collectes: [],
    scrollPosition: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      tous: 0,
    }
  }

  handleTous = (tous) => {
    this.setState({ tous });
  }

  sort = (arr, tous) => {
    switch(tous) {
      case 0:
        return arr;
      case 1:
        return arr.filter(x => x.terminer === false);
      case 2:
        return arr.filter(x => x.terminer === true);
      default:
        return arr;
    }
  }

  renderCollectes = (collectes, scrollPosition, tous) => {
    const sorted_collectes = this.sort(collectes, tous);

    return sorted_collectes.sort((a,b) => b.id - a.id).map(x => {
      return <CollecteCard
          key={x.id}
          data={x}
          color={x.terminer ? "#EFEEEE":x.theme.couleur}
          scrollPosition={scrollPosition}
        />;
    });
  }

  renderFiltres = (tous) => {
    const arr = [
      {
        key: 0,
        value: 'Toutes',
      },
      {
        key: 1,
        value: 'En cours',
      },
      {
        key: 2,
        value: 'Terminées'
      },
    ];

    return arr.map(x => {
      return (
        <p
          key={x.key}
          className={x.key === tous ? "association-filtre active":"association-filtre"}
          onClick={() => this.handleTous(x.key)}
        >
          {x.value}
        </p>
      );
    });
  }

  render() {
    const { collectes, scrollPosition } = this.props;
    const { tous } = this.state;

    return (
      <div className="association-collecte">
        <div className="association-filtre-block">
          {this.renderFiltres(tous)}
        </div>
        <div className="association-collecte-block">
          {this.renderCollectes(collectes, scrollPosition, tous)}
        </div>
      </div>
    );
  }
}

export default trackWindowScroll(Collecte);
