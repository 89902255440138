import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import { Checkbox } from "antd";

import CardSection from "./cardSection";
import UserDataForm from "./userDataForm";

import env from "../../config/env";
const { STATIC_URL } = env;

class CardSetupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      adress: null,
      city: null,
      postalCode: null,
      email: "",
      country: null,
      processing: false,
      error: null,
      save_card: false,
      agree: false,
      email_error: false,
    };
  }

  componentDidMount() {
    if (this.props.user !== null) {
      const { user } = this.props;
      this.setState({
        first_name: user.user.first_name,
        last_name: user.user.last_name,
        adress: user.adresse,
        country: user.pays !== null ? user.pays : "FR",
        city: user.ville,
        postalCode: user.code_postal,
        email: user.user.email,
      });
    } else {
      this.setState({ country: "FR" });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user === null && this.props.user !== null) {
      const { user } = this.props;
      this.setState({
        first_name: user.user.first_name,
        last_name: user.user.last_name,
        adress: user.adresse,
        country: user.pays !== null ? user.pays : "FR",
        city: user.ville,
        postalCode: user.code_postal,
        email: user.user.email,
      });
    }
  }

  componentWillUnmount() {
    this.setState({ processing: false, error: null });
  }

  handleFirstName = (first_name) => {
    this.setState({ first_name });
  };

  handleLastName = (last_name) => {
    this.setState({ last_name });
  };

  handleAdress = (adress) => {
    this.setState({ adress });
  };

  handleCity = (city) => {
    this.setState({ city });
  };

  handleEmail = (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      this.setState({ email, email_error: false });
    } else {
      this.setState({ email, email_error: true });
    }
  };

  handlePostalCode = (postalCode) => {
    this.setState({ postalCode });
  };

  handleCountry = (country) => {
    this.setState({ country });
  };

  handleSaveCard = () => {
    this.setState({
      save_card: !this.state.save_card,
    });
  };

  handleAgree = (userData) => {
    const form = document.getElementById("cardForm");
    const { user, client_secret } = this.props;
    if (form.reportValidity() && this.state.agree === false && user === null) {
      const data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        adresse: this.state.adress,
        city: this.state.city,
        code_postal: this.state.postalCode,
        country: this.state.country,
      };
      this.props.fetchClientSecretNotLogin(data);
      this.setState({ agree: !this.state.agree, error: null });
    } else if (
      form.reportValidity() &&
      this.state.agree === false &&
      user !== null
    ) {
      if (client_secret === null) {
        const data = {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          adresse: this.state.adress,
          city: this.state.city,
          code_postal: this.state.postalCode,
          country: this.state.country,
        };
        this.props.fetchClientSecret(data);
      }
      this.setState({ agree: !this.state.agree, error: null });
    } else if (form.reportValidity() && this.state.agree === true) {
      this.setState({ agree: !this.state.agree });
    } else {
      this.setState({ error: "Remplissez les champs s'il vous plaît" });
    }
  };

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    this.setState({ processing: true });

    const { stripe, elements, client_secret } = this.props;
    const userData = { ...this.state };

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${userData.last_name} ${userData.first_name}`,
          address: {
            line1: userData.adress,
            postal_code: userData.postalCode,
            country: userData.country,
            city: userData.city,
          },
        },
      },
      setup_future_usage: userData.save_card ? "off_session" : null,
    });

    if (result.error) {
      // Display result.error.message in your UI.
      this.setState({ error: result.error.message, processing: false });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        this.props.redirectToDashboard(this.state.email);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  getTotal = (data) => {
    const price = data.reduce((acc, val) => {
      const price_with_heavy = val.price;
      // val.article.is_heavy
      //   ? parseFloat(val.price) + 2.0
      //   : val.price;
      acc = acc + val.quantity * parseFloat(price_with_heavy);
      return acc;
    }, 0.0);

    return price.toFixed(2);

    // if (price > 20.0) {
    //   return ((price * 106) / 100).toFixed(2);
    // } else {
    //   return (price + 1.2).toFixed(2);
    // }
  };

  render() {
    const { stripe, cart, client_secret, children } = this.props;
    const userData = { ...this.state };

    if (!stripe) {
      return null;
    }

    return (
      <form
        id="cardForm"
        onSubmit={this.handleSubmit}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        autoComplete="off"
      >
        <CardSection />
        <div style={{ width: "100%", marginBottom: 20 }}>
          <Checkbox onChange={this.handleSaveCard} checked={userData.save_card}>
            Sauvegarder mes informations bancaires
          </Checkbox>
        </div>
        <UserDataForm
          userData={userData}
          handleLastName={this.handleLastName}
          handleFirstName={this.handleFirstName}
          handleAdress={this.handleAdress}
          handleCity={this.handleCity}
          handlePostalCode={this.handlePostalCode}
          handleCountry={this.handleCountry}
          handleEmail={this.handleEmail}
        />
        <div style={{ width: "100%", marginBottom: 20 }}>
          <Checkbox onChange={this.handleAgree} checked={userData.agree}>
            En cochant cette case j'accepte les{" "}
            <a
              href={`${STATIC_URL}/cgu`}
              target="_blank"
              rel="noopener noreferrer"
            >
              conditions generales
            </a>{" "}
            de Wisdana
          </Checkbox>
        </div>
        {userData.agree && client_secret && (
          <button className="payment-button" disabled={userData.processing}>
            {userData.processing ? "En cours" : `Payer €${this.getTotal(cart)}`}
          </button>
        )}

        {!client_secret && (
          <div className="payment-button-disabled">{`Payer €${this.getTotal(
            cart
          )}`}</div>
        )}

        {userData.error && (
          <div className="card-error" role="alert">
            {userData.error}
          </div>
        )}
        {children}
      </form>
    );
  }
}

export default function InjectedCardSetupForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardSetupForm
          stripe={stripe}
          elements={elements}
          cart={props.cart}
          user={props.user}
          client_secret={props.client_secret}
          redirectToDashboard={(data) => props.redirectToDashboard(data)}
          fetchClientSecretNotLogin={(data) =>
            props.fetchClientSecretNotLogin(data)
          }
          fetchClientSecret={(data) => props.fetchClientSecret(data)}
          children={props.children}
        />
      )}
    </ElementsConsumer>
  );
}
