import {
  AUTH_ERROR,
  AUTH_VERIFICATION,
  AUTH_ACTIVATION,
  FETCH_USER,
  UPDATE_USER,
  ADD_TO_CART,
  SET_INITIAL_CART,
  UPDATE_CART,
  CLEAR_CART,
  SEARCH,
  UPDATE_MAX_QUANTITY,
} from "./types";

const initialState = {
  verify: null,
  user: null,
  error: null,
  cart: [],
  search: null,
};

function updateCart(state, action) {
  const { cart } = state;

  if (cart.length === 0) {
    return state;
  }

  const newCart = [];

  cart.forEach((x) => {
    if (x.id === action.payload.id && action.payload.quantity !== 0) {
      x.quantity = action.payload.quantity;
      newCart.push(x);
    } else if (x.id !== action.payload.id) {
      newCart.push(x);
    }
  });

  localStorage.removeItem("cart");
  localStorage.setItem("cart", JSON.stringify(newCart));

  return { ...state, cart: newCart };
}

function updateMaxQuantity(state, action) {
  const { cart } = state;

  if (cart.length === 0) {
    return state;
  }

  const newCart = [];

  cart.forEach((item) => {
    if (item.id === action.payload.id) {
      item.quantity_available = action.payload.quantity_available;
      newCart.push(item);
    }
  });

  localStorage.removeItem("cart");
  localStorage.setItem("cart", JSON.stringify(newCart));

  return { ...state, cart: newCart };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_ERROR:
      return { ...state, verify: action.verify };
    case AUTH_VERIFICATION:
      return { ...state, verify: action.verify };
    case AUTH_ACTIVATION:
      return { ...state, user: action.user };
    case FETCH_USER:
      return { ...state, user: action.payload };
    case UPDATE_USER:
      return { ...state, user: action.payload };
    case ADD_TO_CART:
      return { ...state, cart: action.payload };
    case SET_INITIAL_CART:
      return { ...state, cart: action.payload };
    case UPDATE_CART:
      return updateCart(state, action);
    case UPDATE_MAX_QUANTITY:
      return updateMaxQuantity(state, action);
    case CLEAR_CART:
      return { ...state, cart: [] };
    case SEARCH:
      return { ...state, search: action.payload };
    default:
      return { ...state };
  }
}
