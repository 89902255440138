import React, { Component } from "react";
import PropTypes from "prop-types";

import { Redirect, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CloseOutlined } from "@ant-design/icons";

import {
  fetchClientSecret,
  fetchCreditCard,
  flushCartAndCreditCard,
  fetchClientSecretNotLogin,
  createCustomerPaypal,
  createPassword,
  updateProductAvailivility,
} from "./actions";

import Cart from "../../components/checkout/cart";
import IndividualItem from "../../components/checkout/individualItem";
import TotalCheckout from "../../components/checkout/totalCheckout";
import Password from "../../components/checkout/password";

import CartLayout from "../../components/layout/cart";

import "./checkout.scss";
import "../../components/checkout/style.scss";

class CheckoutContainer extends Component {
  static propTypes = {
    cart: PropTypes.array.isRequired,
    client_secret: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.string.isRequired,
    ]),
    user: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    card: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    password: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    cart: [],
    client_secret: null,
    user: null,
    card: null,
    password: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      done: false,
      email: null,
    };
  }

  componentDidMount() {
    this.props.updateProductAvailivility();

    if (this.calculateTotalPrice(this.props.cart) < 5.0) {
      this.props.history.goBack();
    }

    if (this.props.cart.length > 0 && this.props.user !== null) {
      if (this.props.user.stripe_customer !== null) {
        this.props.fetchClientSecret({
          cart: this.props.cart,
          first_time: false,
        });
      }
      this.props.fetchCreditCard();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user === null &&
      this.props.user !== null &&
      this.props.cart.length > 0
    ) {
      if (this.props.user.stripe_customer !== null) {
        this.props.fetchClientSecret({
          cart: this.props.cart,
          first_time: false,
        });
      }
      this.props.fetchCreditCard();
    }

    if (prevProps.paypal === null && this.props.paypal !== null) {
      this.setState({ done: true, email: this.props.paypal.user.email });
    }

    if (prevState.done === false && this.state.done === true) {
      this.props.flushCartAndCreditCard();
    }
    return true;
  }

  componentWillUnmount() {
    this.setState({ done: false });
  }

  calculateTotalPrice = (data) => {
    const price = data.reduce((acc, val) => {
      acc = acc + val.quantity * parseFloat(val.price);
      return acc;
    }, 0);

    return price.toFixed(2);
  };

  createCustomerPaypal = (data) => {
    const object = {
      ...data,
      cart: this.props.cart,
    };

    this.props.createCustomerPaypal(object);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  fetchClientSecretNotLogin = (data) => {
    const object = {
      ...data,
      cart: this.props.cart,
    };

    this.props.fetchClientSecretNotLogin(object);
  };

  fetchClientSecret = (data) => {
    const object = {
      ...data,
      cart: this.props.cart,
    };

    this.props.fetchClientSecret(object);
  };

  renderCart = (cart) => {
    return cart.map((x) => {
      return <IndividualItem key={x.id} data={x} />;
    });
  };

  renderTotal = (cart) => {
    return <TotalCheckout data={cart} />;
  };

  redirectToDashboard = (email) => {
    this.setState({ done: true, email: email });
  };

  render() {
    const { client_secret, cart, user, card, password } = this.props;
    const { done, email } = this.state;

    if (password) {
      return <Redirect push={false} to={"/app/feed"} />;
    }

    if (done && user === null) {
      return (
        <div className="checkout-container-block final">
          <div className="checkout-block final-block">
            <div className="thank-you">
              <h2>
                Merci beaucoup pour votre don{" "}
                <span role="img" aria-label="emoji">
                  💪
                </span>
              </h2>
              <p className="thank-you-p">
                Créez votre compte Wisdana et retrouvez le récapitulatif de vos
                dons ainsi que vos reçus fisaux{" "}
                <span role="img" aria-label="emoji">
                  {" "}
                  🤘
                </span>
              </p>
              <Password
                createPassword={(data) => this.props.createPassword(data)}
                email={email}
              />
              <div style={{ marginTop: 20 }}>
                <Link
                  to="/app/feed"
                  style={{
                    color: "#000",
                    textDecoration: "none",
                    fontWeight: 200,
                  }}
                >
                  S'abstenir
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (done) {
      return (
        <div className="checkout-container-block final">
          <div className="checkout-block final-block">
            <div className="thank-you">
              <h2>
                Merci beaucoup pour votre don {this.props.user.user.first_name}{" "}
                <span role="img" aria-label="emoji">
                  💪
                </span>
              </h2>
              <p className="thank-you-p">
                Retrouvez le récapitulatif de vos dons ainsi que les reçus
                fiscaux correspondants sur votre compte Wisdana{" "}
                <span role="img" aria-label="emoji">
                  {" "}
                  🤘
                </span>
              </p>
              <div className="submit">
                <Link to="/app/dashboard">Mon compte</Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {cart.length > 0 && (
          <div className="checkout-container-block">
            <div className="checkout-block left-block">
              <Cart
                client_secret={client_secret}
                cart={cart}
                user={user}
                card={card}
                fetchClientSecretNotLogin={(data) =>
                  this.fetchClientSecretNotLogin(data)
                }
                fetchClientSecret={(data) => this.fetchClientSecret(data)}
                redirectToDashboard={(email) => this.redirectToDashboard(email)}
                createCustomerPaypal={(data) => this.createCustomerPaypal(data)}
                goBack={() => this.goBack()}
              />
            </div>
            <div
              className="checkout-block right-block"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="header">
                <h2>Résumé de la commande</h2>
                <div
                  onClick={() => this.goBack()}
                  style={{
                    display: "block",
                    color: "inherit",
                    marginRight: 20,
                    cursor: "pointer",
                  }}
                >
                  <CloseOutlined />
                </div>
              </div>
              <div className="cart-block-checkout">
                <CartLayout cart={cart} in_checkout={true} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.layout.cart,
    client_secret: state.checkout.client_secret,
    user: state.layout.user,
    card: state.checkout.card,
    password: state.checkout.password,
    paypal: state.checkout.paypal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchClientSecret,
      fetchCreditCard,
      flushCartAndCreditCard,
      fetchClientSecretNotLogin,
      createPassword,
      createCustomerPaypal,
      updateProductAvailivility,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer)
);
