import {
	GET_METRICS,
} from './types';

const initialState = {
  metrics: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_METRICS:
			return { ...state, metrics: action.payload };
		default:
			return { ...state };
	}
}
