import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  ProduitsIcon,
  CollectesIcon,
  DonsIcon
} from '../elements/icons';

export default class Metric extends Component {
  static propTypes = {
    rankings: PropTypes.object.isRequired,
  };

  static defaultProps = {
    rankings: {},
  }

  render() {
    const { rankings } = this.props;

    return (
      <div className="metric-box">
        <div className="metric-item">
          <ProduitsIcon />
          <p className="chiffre">
            {rankings.collectes}
          </p>
          <p className="text">
            {rankings.collectes > 1 ? "collectes":"collecte"}
          </p>
        </div>
        <div className="metric-item">
          <CollectesIcon />
          <p className="chiffre">
            {rankings.produits}
          </p>
          <p className="text">
            {rankings.produits > 1 ? "produits offerts":"produit offert"}
          </p>
        </div>
        <div className="metric-item">
          <DonsIcon />
          <p className="chiffre">
            {rankings.money.toFixed(2)}€
          </p>
          <p className="text">
            {Math.ceil(rankings.money) > 1 ? "de dons":"de don"}
          </p>
        </div>
      </div>
    );
  }
}
