import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getPercent } from '../elements/percent_calculation';

import { TrophyIcon, CollectesIcon, ProduitsIcon, DonsIcon } from '../elements/icons';

export default class Stats extends Component {
  static propTypes = {
    collecte: PropTypes.shape({
      association: PropTypes.object.isRequired,
      date_debut: PropTypes.string.isRequired,
      date_fin: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      distance: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.bool.isRequired]),
      id: PropTypes.number.isRequired,
      lieu_de_livraison: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      percent: PropTypes.object.isRequired,
      photo: PropTypes.string.isRequired,
      sous_theme: PropTypes.object,
      terminer: PropTypes.bool.isRequired,
      promue: PropTypes.bool.isRequired,
      theme: PropTypes.object.isRequired,
    }),
    participants: PropTypes.number.isRequired,
  };

  static defaultProps = {
    collecte: null,
    participants: 0,
  };

  render() {
    const { collecte, participants } = this.props;

    return (
      <div className="stats-box">
        <div className="stats-item">
          <ProduitsIcon />
          <p className="chiffre">
            {getPercent(collecte.percent)}%
          </p>
          <p className="text">
            des produits collectés
          </p>
        </div>
        <div className="stats-item">
			&nbsp;
		  {/*
          <CollectesIcon />
          <p className="chiffre">
            {participants}
          </p>
          <p className="text">
            {participants > 1 ? "participants":"participant"}
          </p>
           */}
        </div>
        <div className="stats-item">
          <DonsIcon />
          <p className="chiffre">
            {collecte.percent.price.toFixed(2)}€
          </p>
          <p className="text">
            {Math.ceil(collecte.percent.price) > 1 ? "de dons reçus":"de don reçu"}
          </p>
        </div>
        <div className="stats-item">
          <TrophyIcon />
          <p className="chiffre">
            {collecte.percent.total - collecte.percent.quantity_available}
          </p>
          <p className="text">
            {(collecte.percent.total - collecte.percent.quantity_available) > 1 ? "produits offerts":"produit offert"}
          </p>
        </div>
      </div>
    );
  }
}
