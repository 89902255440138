import axios from 'axios';

import {
	AUTH_ERROR,
	AUTH_VERIFICATION,
  UNAUTH_USER,
	AUTH_USER,
	FETCH_USER,
	ADD_TO_CART,
	UPDATE_CART,
	SEARCH,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const REFRESH_URL = API_ROUTES.token_refresh;
const VERIFY_URL = API_ROUTES.token_verify;
const CUSTOMER_URL = API_ROUTES.customer;
const SEARCH_URL = API_ROUTES.search;

const headers = localStorage.getItem('wisdana-customer-token') !== null ? { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }:{};

export function addToCart(data) {
	return function(dispatch) {
		localStorage.setItem('cart', JSON.stringify(data));

		dispatch({ type: ADD_TO_CART, payload: data });
	}
}

export function updateCart(data) {
	return function(dispatch) {
		dispatch({ type: UPDATE_CART, payload: data });
	}
}

export function refreshToken() {
	return function(dispatch) {
		axios.post(`${REFRESH_URL}`, { token: localStorage.getItem('wisdana-customer-token') })
		.then(response => {
			localStorage.setItem('wisdana-customer-token', response.data.token);

			dispatch({ type: AUTH_USER, authenticated: true, activation: true });
		})
		.catch(err => {
			localStorage.removeItem('wisdana-customer-token');
			dispatch({ type: UNAUTH_USER });
		});
	}
}

export function verifyToken() {
	return function(dispatch) {
		axios.post(`${VERIFY_URL}`, { token: localStorage.getItem('wisdana-customer-token') })
		.then(response => {
			dispatch({ type: AUTH_VERIFICATION, verify: true, authed: true });
		})
		.catch(err => {
			localStorage.removeItem('wisdana-customer-token');
			dispatch({ type: AUTH_ERROR, verify: false, error: null });
		});
	}
}

export function searchFunction(data) {
	return function(dispatch) {
		axios.get(`${SEARCH_URL}?name=${data}`, {
			headers
		})
		.then(response => {
			dispatch({ type: SEARCH, payload: response.data });
		})
		.catch(err => {
			console.log(err.response);
			dispatch({ type: SEARCH, payload: err.response.data.error });
		});
	}
}

export function refreshSearch() {
	return function(dispatch) {
		dispatch({ type: SEARCH, payload: null });
	}
}

export function refresh() {
	return function(dispatch) {
		dispatch({ type: AUTH_VERIFICATION, verify: null, authed: null });
		dispatch({ type: SEARCH, payload: null });
	}
}

export function authError(error) {
	return {
		type: AUTH_ERROR,
		error: error
	};
}

export function getUser() {
	return function(dispatch) {
		axios.get(`${CUSTOMER_URL}`, {
			headers: { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }
		})
		.then(response => {
			dispatch({ type: FETCH_USER, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		})
	}
}
