import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import {
  refresh,
  getAssociation,
  getCollectes,
  getParticipantsNumber,
} from './actions';

import './association.scss';
import '../../components/association/style.scss';

import Subheader from '../../components/layout/subheader';
import Description from '../../components/association/description';
// import Updates from '../../components/association/updates';
import Collecte from '../../components/association/collecte';
import AssociationDetails from '../../components/association/associationDetails';
import Stats from '../../components/association/stats';


class CollecteContainer extends Component {
  static propTypes = {
    associationId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    associationId: null,
  };

  constructor(props){
    super(props);
    this.state = {
      mobile_selected: 0,
    }
  }

  componentDidMount() {
    this.props.getAssociation(this.props.associationId);
    this.props.getCollectes(this.props.associationId);
    this.props.getParticipantsNumber(this.props.associationId);
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  handleMobileSelected = (mobile_selected) => {
    this.setState({ mobile_selected });
  }

  renderMobileMenu = (mobile_selected) => {
    const arr = [
      {
        key: 0,
        value: 'Description',
      },
      {
        key: 1,
        value: 'Stats',
      },
      {
        key: 2,
        value: 'Collectes',
      },
      {
        key: 3,
        value: 'Informations',
      },
      // {
      //   key: 4,
      //   value: 'Actualités',
      // },
    ];

    return arr.map(x => {
      return (
        <div
          key={x.key}
          className={x.key === mobile_selected ? "item active":"item"}
          onClick={() => this.handleMobileSelected(x.key)}
        >
          {x.value}
        </div>
      );
    });
  }

  renderMobile = (mobile_selected, association, collectes, participant) => {
    switch(mobile_selected) {
      case 0:
        return (
          <div className="block mobile">
            <Description
              association={association}
              collecte={false}
            />
          </div>
        );
      case 1:
        return (
          <div className="block mobile" style={{ textAlign: 'center' }}>
            <Stats
              collectes={collectes}
              participant={participant}
            />
          </div>
        );
      case 2:
        return (
          <div className="block mobile">
            <Collecte
              collectes={collectes}
            />
          </div>
        );
      case 3:
        return (
          <div className="block mobile">
            <AssociationDetails
              association={association}
            />
          </div>
        );
      // case 4:
      //   return (
      //     <div className="collecte-updates mobile">
      //       <Updates
      //         color={association.main_theme.couleur}
      //       />
      //     </div>
      //   );
      default:
        return (
          <div className="block mobile">
            <Description
              association={association}
              collecte={false}
            />
          </div>
        );
    }
  }

  render() {
    const { association, collectes, participant } = this.props;
    const { mobile_selected } = this.state;

    return (
      <div>
        <Subheader />
        {association &&  collectes !== null && participant !== null &&
        <div className="collecte-container">
          <div className="mobile-menu-collecte">
            {this.renderMobileMenu(mobile_selected)}
          </div>
          <div className="block">
            <Description
              association={association}
              collecte={false}
            />
          </div>
          <div className="block" style={{ textAlign: 'center' }}>
            <Stats
              collectes={collectes}
              participant={participant}
            />
          </div>
          <div className="block">
            <Collecte
              collectes={collectes}
            />
          </div>
          <div className="block" style={{ textAlign: 'center' }}>
            <AssociationDetails
              association={association}
            />
          </div>
          {this.renderMobile(mobile_selected, association, collectes, participant)}
        </div>
        }
        {/*association &&
          <div className="collecte-updates">
            <Updates
              color={association.main_theme.couleur}
            />
          </div>
        */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    association: state.association.association,
    collectes: state.association.collectes,
    participant: state.association.participant,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAssociation,
    getCollectes,
    getParticipantsNumber,
    refresh,
  },dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(CollecteContainer)
