import {
	GET_COLLECTE,
	GET_PARTICIPANTS,
	GET_ITEMS,
	GET_ACTUALITES,
} from './types';

const initialState = {
  collecte: null,
	participants: null,
	items: null,
	actualites: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_COLLECTE:
			return { ...state, collecte: action.payload };
		case GET_PARTICIPANTS:
			return { ...state, participants: action.payload };
		case GET_ITEMS:
			return { ...state, items: action.payload };
		case GET_ACTUALITES:
			return { ...state, actualites: action.payload };
		default:
			return { ...state };
	}
}
