// export * from './pages/home/reducer';
import { combineReducers } from 'redux';
import AuthReducer from '../containers/auth/reducers';
import LayoutReducer from '../containers/layout/reducers';
import ResetPasswordReducer from '../containers/reset_password/reducers';
import AuthActivationReducer from '../containers/auth_activation/reducers';
import FeedReducer from '../containers/feed/reducers';
import DashboardReducer from '../containers/dashboard/reducers';
import CollecteReducer from '../containers/collecte/reducers';
import CheckoutReducer from '../containers/checkout/reducers';
import AssociationReducer from '../containers/association/reducers';
import LandingReducer from '../containers/landing/reducers';

const rootReducer = combineReducers({
  auth: AuthReducer,
  layout: LayoutReducer,
  reset_password: ResetPasswordReducer,
  auth_activation: AuthActivationReducer,
  feed: FeedReducer,
  dashboard: DashboardReducer,
  collecte: CollecteReducer,
  checkout: CheckoutReducer,
  association: AssociationReducer,
  landing: LandingReducer,
});

export default rootReducer;
