import React from 'react';

import ProduitsLogo from '../../resources/img/produits.svg';
import CollectesLogo from '../../resources/img/collectes.svg';
import DonsLogo from '../../resources/img/dons.svg';
import TrophyLogo from '../../resources/img/trophy.svg';


const ProduitsIcon = () => {
  return (
    <div className="logo fa-3x" style={{ backgroundImage: `url('${ProduitsLogo}')`, color: '#6A9BF4' }}>
      <i className="fal fa-box-full"></i>
    </div>
  );
};

const CollectesIcon = () => {
  return (
    <div className="logo fa-3x" style={{ backgroundImage: `url('${CollectesLogo}')`, color: '#F7517A' }}>
      <i className="fal fa-people-carry"></i>
    </div>
  );
};

const DonsIcon = () => {
  return (
    <div className="logo fa-3x" style={{ backgroundImage: `url('${DonsLogo}')`, color: '#FFBD5C' }}>
      <i className="fal fa-coins"></i>
    </div>
  );
};

const TrophyIcon = () => {
  return (
    <div className="logo fa-3x" style={{ backgroundImage: `url('${TrophyLogo}')`, color: '#8982F6' }}>
      <i className="fal fa-trophy-alt"></i>
    </div>
  );
};

export { ProduitsIcon, CollectesIcon, DonsIcon, TrophyIcon };
