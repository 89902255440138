import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { EnvironmentFilled, PhoneFilled, MailFilled } from '@ant-design/icons';

export default class AssociationDetails extends Component {
  static propTypes = {
    association: PropTypes.shape({
      user: PropTypes.number.isRequired,
      adresse: PropTypes.string.isRequired,
      adresse_de_livraison: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      nom: PropTypes.string.isRequired,
      latitude: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      telephone: PropTypes.string.isRequired,
      nombre_de_membre: PropTypes.number.isRequired,
      site_web: PropTypes.string.isRequired,
      grand_label_associatif: PropTypes.string.isRequired,
      main_theme: PropTypes.object.isRequired,
    }),
  };

  static defaultProps = {
    association: {},
  };


  render() {
    const { association } = this.props;
    return (
      <div className="association-details-block">
        <div className="association-details" style={{ borderRightColor: association.main_theme.couleur }}>
          {association.logo !== null ? <div className="logo" style={{ backgroundImage: `url('${association.logo}')`}}></div>:null}
          <h2>
            {association.nom}
          </h2>
        </div>
        <div className="association-details" style={{ borderRightColor: association.main_theme.couleur }}>
          <EnvironmentFilled style={{ color: association.main_theme.couleur, fontSize: 28 }} />
          <p>{association.adresse}</p>
        </div>
        <div className="association-details" style={{ borderRightColor: association.main_theme.couleur }}>
          <PhoneFilled style={{ color: association.main_theme.couleur, fontSize: 28 }} />
          <p>{association.telephone}</p>
        </div>
        <div className="association-details">
          <MailFilled style={{ color: association.main_theme.couleur, fontSize: 28 }} />
          <p>{association.site_web}</p>
        </div>
      </div>
    );
  }
}
