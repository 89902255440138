import {
	GET_ASSOCIATION,
	GET_COLLECTES,
	GET_PARTICIPANTS_NUMBER,
} from './types';

const initialState = {
  association: null,
	collectes: null,
	participant: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_ASSOCIATION:
			return { ...state, association: action.payload };
		case GET_COLLECTES:
			return { ...state, collectes: action.payload };
		case GET_PARTICIPANTS_NUMBER:
			return { ...state, participant: action.payload };
		default:
			return { ...state };
	}
}
