import env from "../config/env";
const { API_HOST } = env;

const API_ROUTES = {
  login: `${API_HOST}/app/login/`,
  signup: `${API_HOST}/app/signup/`,
  activation_route: `${API_HOST}/app/account-activation/`,
  token_verify: `${API_HOST}/app/api-token-verify/`,
  token_refresh: `${API_HOST}/app/api-token-refresh/`,
  password_reset: `${API_HOST}/app/reset-password/`,
  password_reset_confirm: `${API_HOST}/app/reset-password/checkToken/`,
  password_reset_done: `${API_HOST}/app/reset-password/done/`,
  customer: `${API_HOST}/app/customer/`,
  themes: `${API_HOST}/app/themes/`,
  feed: `${API_HOST}/app/feed/`,
  update_user: `${API_HOST}/app/update-user/`,
  rankings: `${API_HOST}/app/rankings/`,
  collecte: `${API_HOST}/app/collecte/`,
  stripe: `${API_HOST}/app/stripe-intent/`,
  stripe_card: `${API_HOST}/app/stripe-card/`,
  association: `${API_HOST}/app/association/`,
  update_password: `${API_HOST}/app/update-password/`,
  update_product_availivility: `${API_HOST}/app/collectes/items/availability/`,
  orders: `${API_HOST}/app/orders/`,
  stripe_not_login: `${API_HOST}/app/stripe-intent-not-login/`,
  create_password: `${API_HOST}/app/create-password/`,
  search: `${API_HOST}/search/search/`,
  paypal_order: `${API_HOST}/app/paypal-order/`,
  landing: `${API_HOST}/app/landing/`,
  applications: `${API_HOST}/equipe/applications/`,
};

export default API_ROUTES;
