import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Metric from './metric';
import Orders from './orders';

export default class Collecte extends Component {
  static propTypes = {
    rankings: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    orders: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    getRecuFiscalLink: PropTypes.func.isRequired,
    user: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    recu_fiscal: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    cleanRecuFiscal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    rankings: null,
    orders: null,
    getRecuFiscalLink: () => {},
    user: null,
    recu_fiscal: null,
    cleanRecuFiscal: () => {},
  }

  render() {
    const { rankings, orders, user, recu_fiscal } = this.props;

    return (
      <div className="collecte-container">
        {rankings &&
          <Metric
            rankings={rankings}
          />
        }
        {orders && user &&
          <Orders
            orders={orders}
            user={user}
            getRecuFiscalLink={(id) => this.props.getRecuFiscalLink(id)}
            recu_fiscal={recu_fiscal}
            cleanRecuFiscal={() => this.props.cleanRecuFiscal()}
          />
        }
      </div>
    );
  }
}
