import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import scriptLoader from "react-async-script-loader";

import env from "../../config/env";
const { PAYPAL_PUBLIC_KEY } = env;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

let PaypalButton = null;

class Paypal extends Component {
  static propTypes = {
    user: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    cart: PropTypes.array.isRequired,
    createCustomer: PropTypes.func.isRequired,
    info: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      email: null,
      first_name: null,
      last_name: null,
      payerID: null,
      orderID: null,
      processed: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PaypalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PaypalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ showButtons: true });
      }
    }
  }

  getFraisService = (cart) => {
    return 0;
    // const price = cart.reduce((acc, val) => {
    //   const price_with_heavy = val.article.is_heavy
    //     ? parseFloat(val.price) + 2.0
    //     : val.price;
    //   acc = acc + val.quantity * parseFloat(price_with_heavy);
    //   return acc;
    // }, 0.0);

    // if (price > 20.0) {
    //   return ((price * 6) / 100).toFixed(2);
    // } else {
    //   return (1.2).toFixed(2);
    // }
  };

  getAmount = (cart) => {
    const price = cart.reduce((acc, val) => {
      // const price_with_heavy = val.price;
      // val.article.is_heavy
      //   ? parseFloat(val.price) + 2.0
      //   : val.price;
      acc = acc + val.quantity * parseFloat(val.price);
      return acc;
    }, 0.0);

    return price.toFixed(2);
    // if (price > 20.0) {
    //   return ((price * 106) / 100).toFixed(2);
    // } else {
    //   return (price + 1.2).toFixed(2);
    // }
  };

  createOrder = (data, actions, amount, items) => {
    this.setState({ loading: true });
    return actions.order.create({
      purchase_units: [
        {
          description: "Wisdana",
          amount: {
            currency_code: "EUR",
            value: amount,
            breakdown: {
              item_total: {
                value: amount,
                currency_code: "EUR",
              },
            },
          },
          items: items,
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  getCart = () => {
    const { cart } = this.props;
    const new_cart = cart;

    return new_cart.map((x) => {
      return {
        name: `${x.article.ref} (${x.article.id})-collecte:${x.collecte.id}`,
        unit_amount: {
          value: parseFloat(x.price).toFixed(2),
          currency_code: "EUR",
        },
        description: `Wisdana-${x.article.ref} (${x.article.id})-collecte:${x.collecte.id}`,
        sku: x.article.ref,
        quantity: `${x.quantity}`,
      };
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then((details) => {
      this.setState({ showButtons: false, loading: false });

      const new_data = {
        payerID: data.payerID,
        orderID: data.orderID,
        email: details.payer.email_address.toLowerCase().trim(),
        first_name: details.payer.name.given_name,
        last_name: details.payer.name.surname,
        address: {
          address_line_1: details.payer.address?.address_line_1,
          address_line_2: details.payer.address?.address_line_2,
          admin_area_1: details.payer.address?.admin_area_1,
          admin_area_2: details.payer.address?.admin_area_2,
          postal_code: details.payer.address?.postal_code,
          country_code: details.payer.address?.country_code,
        },
      };

      this.props.createCustomer(new_data);
    });
  };

  onCancel = (data, actions) => {
    this.setState({ showButtons: true, loading: false });
  };

  onError = (data, actions) => {
    console.error(data);
  };

  render() {
    const { showButtons, loading } = this.state;
    const { info, cart } = this.props;

    // const frais_service = {
    //   name: "Frais de service",
    //   unit_amount: {
    //     value: this.getFraisService(this.props.cart),
    //     currency_code: "EUR",
    //   },
    //   description: "Wisdana-Frais de service",
    //   sku: "FraisDeService",
    //   quantity: "1",
    // };

    return (
      <div className="paypal-container">
        {info && <h2>Paiement express</h2>}
        {info && <p>Toutes les transactions sont chiffrées et sécurisées</p>}
        {showButtons && (
          <PaypalButton
            createOrder={(data, actions) =>
              this.createOrder(data, actions, this.getAmount(cart), [
                // frais_service,
                ...this.getCart(),
              ])
            }
            onApprove={(data, actions) => this.onApprove(data, actions)}
            onCancel={(data, actions) => this.onCancel(data, actions)}
            onError={(data, actions) => this.onError(data, actions)}
          />
        )}
        {loading && <Spin indicator={antIcon} />}
      </div>
    );
  }
}

export default scriptLoader(
  `https://www.paypal.com/sdk/js?client-id=${PAYPAL_PUBLIC_KEY}&disable-funding=card&currency=EUR`
)(Paypal);
