import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

export default class ResetPasswordDone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
    }
  }

  static propTypes = {
    resetPasswordDone: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    message: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.string.isRequired,
    ]),
  };

  static defaultProps = {
    resetPasswordDone: () => {},
    error: null,
    message: null,
  };

  changePassword = (password) => {
    this.setState({ password });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.resetPasswordDone({ password: this.state.password });
  }

  eightCharCheck = (password) => {
    if (/^.{8,}$/.test(password)) {
      return <p className="done"><CheckOutlined />  8 caractères minimum</p>;
    }
    return <p>8 caractères minimum</p>;
  }

  oneNumberCheck = (password) => {
    if (/^.*\d+.*$/.test(password)) {
      return <p className="done"><CheckOutlined />  1 chiffre</p>;
    }
    return <p>1 chiffre</p>;
  }

  oneMajCheck = (password) => {
    if (/^.*[A-Z]+.*$/.test(password)) {
      return <p className="done"><CheckOutlined />  1 majuscule</p>;
    }
    return <p>1 majuscule</p>;
  }

  render() {
    const { error, message } = this.props;

    return (
      <div className="loginInputContainer">
        {message === null &&
          <h2 className="title">
            Retrouver mon mot de passe
          </h2>
        }
        {message === null &&
          <p className="subtitle">
            Entrer votre nouveau mot de passe ci-dessous.
          </p>
        }
        {message !== null &&
          <p className="subtitle">
            {message}
          </p>
        }
        {message === null &&
          <form onSubmit={e => this.handleSubmit(e)}>
          <div className="multiple-input">
            <div className="div-input">
              <label>Choisissez un mot de passe</label>
              <Input.Password
                placeholder="Mot de passe"
                name="password"
                value={this.state.password}
                onChange={(e) => this.changePassword(e.target.value)}
              />
            </div>
            <div className="div-input">
              <div className="password-checker">
                {this.eightCharCheck(this.state.password)}
                {this.oneNumberCheck(this.state.password)}
                {this.oneMajCheck(this.state.password)}
              </div>
            </div>
          </div>
            {error !== null &&
              <div className="errorDiv">
                <p>{error}</p>
              </div>
            }
            <div className="submit">
              <button type="submit">Envoyer</button>
            </div>
          </form>
        }
      </div>
    );
  }
}
