import {
	GET_THEMES,
	GET_FEED,
} from './types';

const initialState = {
  themes: null,
	feed: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_THEMES:
			return { ...state, themes: action.themes };
		case GET_FEED:
			return { ...state, feed: action.feed } ;
		default:
			return { ...state };
	}
}
