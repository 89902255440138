import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Image from '../elements/image-single';

class Description extends Component {
  static propTypes = {
    association: PropTypes.shape({
      adresse: PropTypes.string,
      adresse_de_livraison: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      nom: PropTypes.string.isRequired,
      latitude: PropTypes.string,
      photo: PropTypes.string,
      longitude: PropTypes.string,
      telephone: PropTypes.string,
      site_web: PropTypes.string,
      grand_label_associatif: PropTypes.string,
    }),
    collecte: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    association: null,
    collecte: false,
  };

  render() {
    const { association, collecte } = this.props;

    if (association === null) {
      return <div className="description-box skeleton"></div>;
    }

    return (
      <div className="description-box">
        <div className="description-box-upper">
          <div className="description-box-image">
            <Image
              image={{
                alt: association.nom,
                height: 280,
                src: association.photo
              }}
            />
          </div>
          <div className="description-box-text">
            <div className="description-box-text-association">
              <div className="logo" style={{ backgroundImage: `url(${association.logo})` }}></div>
              <h1 className="description-box-title">
                {association.nom}
              </h1>
            </div>
            <p className="description-box-paragraph">
              {association.description}
            </p>
            {/*<div className="description-box-text-label-associatif">
              {association.grand_label_associatif.includes("Don en Confiance") &&
              <img
                src="https://wisdana.ams3.digitaloceanspaces.com/static/landing/logo_donenconfiance.png"
                alt="don en confiance"
                className="grand-label-associatif"
              />
              }
              {association.grand_label_associatif.includes("Ideas") &&
              <img
                src="https://wisdana.ams3.digitaloceanspaces.com/static/landing/logo_label_ideas.png"
                alt="don en confiance"
                className="grand-label-associatif"
              />
              }
            </div>*/}
            {collecte &&
              this.props.children
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Description;
