import React, { Component } from "react";
import PropTypes from "prop-types";

export default class TotalCheckout extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  calculatePrice = (data) => {
    const price = data.reduce((acc, val) => {
      const price_with_heavy = val.article.is_heavy
        ? parseFloat(val.price) + 2.0
        : val.price;
      acc = acc + val.quantity * parseFloat(price_with_heavy);
      return acc;
    }, 0.0);

    return price.toFixed(2);
  };

  calculatePriceDéfiscalisation = (data) => {
    const price = data.reduce((acc, val) => {
      const price_with_heavy = val.price_after_defiscalisation;
      // val.article.is_heavy
      //   ? parseFloat(val.price) + 2.0
      //   : val.price;
      return acc + price_with_heavy * val.quantity;
    }, 0.0);

    return price.toFixed(2);
  };

  render() {
    const { data } = this.props;

    return [
      <div key={1} className="cart-total-checkout">
        <div className="cart-total-div">
          <h3>Sous total</h3>
          <h3 style={{ fontWeight: 500 }}>€{this.calculatePrice(data)}</h3>
        </div>
        <div className="cart-total-div">
          <h3>Total après défiscalisation</h3>
          <h3 style={{ fontWeight: 500 }}>
            €{this.calculatePriceDéfiscalisation(data)}
          </h3>
        </div>
      </div>,
      <div key={2} className="cart-total-checkout">
        <div className="cart-total-div">
          <h1>Total</h1>
          <h1 style={{ fontWeight: 500 }}>€{this.calculatePrice(data)}</h1>
        </div>
      </div>,
    ];
  }
}
