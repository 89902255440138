import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getPercent } from '../elements/percent_calculation';

import { TrophyIcon, CollectesIcon, ProduitsIcon, DonsIcon } from '../elements/icons';

export default class Stats extends Component {
  static propTypes = {
    collectes: PropTypes.arrayOf(
      PropTypes.shape({
        association: PropTypes.object.isRequired,
        date_debut: PropTypes.string.isRequired,
        date_fin: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        distance: PropTypes.oneOfType([PropTypes.string.isRequired,PropTypes.bool.isRequired]),
        id: PropTypes.number.isRequired,
        lieu_de_livraison: PropTypes.string.isRequired,
        nom: PropTypes.string.isRequired,
        percent: PropTypes.object.isRequired,
        photo: PropTypes.string.isRequired,
        sous_theme: PropTypes.object.isRequired,
        terminer: PropTypes.bool.isRequired,
        promue: PropTypes.bool.isRequired,
        theme: PropTypes.object.isRequired,
      }),
    ),
    participants: PropTypes.number.isRequired,
  };

  static defaultProps = {
    collectes: null,
    participants: 0,
  };

  getPercents = (data) => {
    const quantity_available = data.reduce((acc, val) => {
      acc += val.percent.quantity_available;
      return acc;
    }, 0);
    const total = data.reduce((acc, val) => {
      acc += val.percent.total;
      return acc;
    }, 0);
    const percent = getPercent({ total, quantity_available });
    return percent;
  }

  getTotal = (data) => {
    const total = data.reduce((acc, val) => {
      acc += val.percent.total;
      return acc;
    }, 0);
    return total;
  }

  getQuantityAvailable = (data) => {
    const quantity_available = data.reduce((acc, val) => {
      acc += val.percent.quantity_available;
      return acc;
    }, 0);
    return quantity_available;
  }

  getPrice = (data) => {
    const price = data.reduce((acc, val) => {
      acc += val.percent.price;
      return acc;
    }, 0.00);
    return price.toFixed(2);
  }

  render() {
    const { collectes, participant } = this.props;

    return (
      <div className="stats-box">
        <div className="stats-item">
          <ProduitsIcon />
          <p className="chiffre">
            {this.getPercents(collectes)}%
          </p>
          <p className="text">
            des produits collectés
          </p>
        </div>
        <div className="stats-item">
          <CollectesIcon />
          <p className="chiffre">
            {participant}
          </p>
          <p className="text">
            participants
          </p>
        </div>
        <div className="stats-item">
          <DonsIcon />
          <p className="chiffre">
            {this.getPrice(collectes)}€
          </p>
          <p className="text">
            de dons reçus
          </p>
        </div>
        <div className="stats-item">
          <TrophyIcon />
          <p className="chiffre">
            {this.getTotal(collectes) - this.getQuantityAvailable(collectes)}
          </p>
          <p className="text">
            produits offerts
          </p>
        </div>
      </div>
    );
  }
}
