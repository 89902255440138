import {
	STRIPE_CLIENT_SECRET,
	STRIPE_CARD,
	CREATE_PASSWORD,
	PAYPAL_ORDER,
} from './types';

const initialState = {
  client_secret: null,
	card: null,
	password: false,
	paypal: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case STRIPE_CLIENT_SECRET:
			return { ...state, client_secret: action.payload };
		case STRIPE_CARD:
			return { ...state, card: action.payload };
		case CREATE_PASSWORD:
			return { ...state, password: action.payload };
		case PAYPAL_ORDER:
			return { ...state, paypal: action.payload };
		default:
			return { ...state };
	}
}
