import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Unauth Routes
import LoginContainer from "../containers/auth/authContainer";
import ActivationContainer from "../containers/auth_activation/authActivationContainer";
import LogoutContainer from "../containers/auth/logoutContainer";
import ResetPasswordContainer from "../containers/reset_password/resetPasswordContainer";
import MaintenancePage from "../components/maintenance";

// Static Routes has been moved to STATIC_URL
// import LandingContainer from "../containers/landing/landingContainer";
// import FAQ from "../components/static/faq";
// import CommentCaMarche from "../components/static/commentCaMarche";
// import Legal from "../components/static/legal";

// Auth Routes
import Layout from "../containers/layout/layoutContainer";

const getRoutes = (maintenance) => {
  // <Route path="/" exact component={LandingContainer} />

  return maintenance ? (
    <Switch>
      <Route path="/app/maintenance" exact render={() => <MaintenancePage />} />
      <Route
        render={() => {
          return <Redirect to="/app/maintenance" />;
        }}
      />
    </Switch>
  ) : (
    <Switch>
      <Route
        path="/app"
        exact
        render={() => {
          return <Redirect to="/app/feed" />;
        }}
      />
      <Route
        path="/"
        exact
        render={() => {
          return <Redirect to="/app/feed" />;
        }}
      />
      <Route
        path="/app/maintenance"
        exact
        render={() => <Redirect to="/app/feed" />}
      />
      <Route path="/app/login" exact component={LoginContainer} />
      <Route path="/app/activation/:id" exact component={ActivationContainer} />
      <Route path="/app/signout" exact component={LogoutContainer} />
      <Route
        path="/app/reset-password"
        exact
        component={ResetPasswordContainer}
      />
      {/*  Now hosted on $STATIC_URL
       <Route path="/faq" exact component={FAQ} />
       <Route path="/comment-ca-marche" exact component={CommentCaMarche} />
       <Route path="/legal" exact component={Legal} />
      */}
      <Layout />
    </Switch>
  );
};

export default getRoutes;
