import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getThemes, getFeed } from "./actions";

import Themes from "../../components/feed/themes";
import CollecteBlock from "../../components/feed/collecteBlock";
import Skeleton from "../../components/feed/skeleton";

import "../../components/feed/style.scss";
import "./feed.scss";

class FeedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: 1,
      sousTheme: [],
    };
  }

  static propTypes = {
    themes: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    feed: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    themes: null,
    feed: null,
  };

  componentDidMount() {
    this.props.getFeed(this.state.theme);
    this.props.getThemes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.themes === null && this.props.themes === null) {
      this.props.getThemes();
    }

    if (prevProps.feed === null && this.props.feed === null) {
      this.props.getFeed(this.state.theme);
    }

    return true;
  }

  changeTheme = (theme) => {
    this.setState({ theme, sousTheme: [] });
    this.props.getFeed(theme);
  };

  onSelectSousTheme = (sousTheme) => {
    this.setState({ sousTheme });
  };

  render() {
    const { themes, feed } = this.props;
    const { theme, sousTheme } = this.state;

    return (
      <div style={{ minHeight: "100vh" }}>
        {themes && (
          <Themes
            themes={themes}
            theme={theme}
            changeTheme={(theme) => this.changeTheme(theme)}
          />
        )}
        {feed === null && <Skeleton />}
        {themes && feed && (
          <CollecteBlock
            feed={feed}
            theme={themes.find((x) => x.id === theme)}
            sousTheme={sousTheme}
            onSelectSousTheme={(data) => this.onSelectSousTheme(data)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    themes: state.feed.themes,
    feed: state.feed.feed,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getThemes,
      getFeed,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedContainer);
