import React, { Component } from "react";
import PropTypes from "prop-types";

import "./style.scss";

import Association from "../elements/associationCard";
import Collecte from "../elements/collecteCard";

import { trackWindowScroll } from "react-lazy-load-image-component";

class Search extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    refreshSearch: PropTypes.func.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    search: {},
    refreshSearch: () => {},
    scrollPosition: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }

  handleSelected = (selected) => {
    this.setState({ selected });
  };

  renderSearchMenu = (selected, search) => {
    return [
      // <div
      //   key={0}
      //   className={0 === selected ? "item active" : "item"}
      //   onClick={() => this.handleSelected(0)}
      // >
      //   {search.associations.length > 1 ? "Associations" : "Association"}
      //   <sup style={{ color: "#2b8bbc", fontWeight: 700, marginLeft: 5 }}>
      //     {search.associations.length > 0 && search.associations.length}
      //   </sup>
      // </div>,
      <div
        key="collect-search-header"
        className={1 === selected ? "item active" : "item"}
        onClick={() => this.handleSelected(1)}
      >
        {search.collectes.length > 1 ? "Collectes" : "Collecte"}
        <sup style={{ color: "#2b8bbc", fontWeight: 700, marginLeft: 5 }}>
          {search.collectes.length}
        </sup>
      </div>,
    ];
  };

  renderCorrect = (selected, data, scrollPosition) => {
    // if (selected === 0) {
    //   return data.associations.map((x) => {
    //     return (
    //       <Association
    //         key={x.id}
    //         data={x}
    //         color={x?.main_theme?.couleur}
    //         scrollPosition={scrollPosition}
    //       />
    //     );
    //   });
    // }
    return data.collectes.map((x) => {
      return (
        <Collecte
          key={x.id}
          data={x}
          color={x.theme.couleur}
          scrollPosition={scrollPosition}
        />
      );
    });
  };

  render() {
    const { selected } = this.state;
    const { search, scrollPosition } = this.props;

    return (
      <div className="search-container">
        <div
          onClick={this.props.refreshSearch}
          style={{ width: 30, float: "right", paddingTop: 20, marginRight: 40 }}
        >
          <i className="fal fa-2x fa-times" style={{ cursor: "pointer" }}></i>
        </div>
        {search.collectes.length === 0 && (
          <div className="search-menu">
            Aucun résultat ne correspond à votre recherche
          </div>
        )}
        {/* {search.collectes.length > 0 && (
          <div className="search-menu">
            {this.renderSearchMenu(selected, search)}
          </div>
        )} */}
        {search.collectes.length > 0 && (
          <div className="search-box">
            {this.renderCorrect(selected, search, scrollPosition)}
          </div>
        )}
      </div>
    );
  }
}

export default trackWindowScroll(Search);
