import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './style.scss';

export default class Subheader extends Component {
  static propTypes = {
    collecte: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    collecte: false,
  };

  render() {
    const { collecte } = this.props;
    
    return (
      <div className="dashboard-header">
      {collecte && (
        <div>
          {collecte.portal_activated === false ? (
          <div className="cta-div">
              <Link to={'/app/feed'}>
                <ArrowLeftOutlined />
                Retour aux collectes
              </Link>
          </div>
          ) : null}
        </div>
      )}
      </div>
    );
  }
}
