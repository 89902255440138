import axios from "axios";

import {
  GET_COLLECTE,
  GET_PARTICIPANTS,
  GET_ITEMS,
  GET_ACTUALITES,
} from "./types";

import API_ROUTES from "../../utils/api_routes";

const COLLECTE_URL = API_ROUTES.collecte;

const headers =
  localStorage.getItem("wisdana-customer-token") !== null
    ? { Authorization: `JWT ${localStorage.getItem("wisdana-customer-token")}` }
    : {};

export function getCollecte(id) {
  return function (dispatch) {
    axios
      .get(`${COLLECTE_URL}${id}/`, {
        headers,
      })
      .then((response) => {
        dispatch({ type: GET_COLLECTE, payload: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
}

export function getActualites(id) {
  return function (dispatch) {
    axios
      .get(`${COLLECTE_URL}${id}/actualites/`, {
        headers,
      })
      .then((response) => {
        dispatch({ type: GET_ACTUALITES, payload: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
}

export function getParticipants(id) {
  return function (dispatch) {
    dispatch({ type: GET_PARTICIPANTS, payload: {} });
    // axios
    //   .get(`${COLLECTE_URL}${id}/participants/`, {
    //     headers,
    //   })
    //   .then((response) => {
    //     dispatch({ type: GET_PARTICIPANTS, payload: response.data });
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
  };
}

export function getItems(id) {
  return function (dispatch) {
    axios
      .get(`${COLLECTE_URL}${id}/items/`, {
        headers,
      })
      .then((response) => {
        dispatch({ type: GET_ITEMS, payload: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
}

export function refresh() {
  return function (dispatch) {
    dispatch({ type: GET_COLLECTE, payload: null });
    dispatch({ type: GET_PARTICIPANTS, payload: null });
    dispatch({ type: GET_ITEMS, payload: null });
    dispatch({ type: GET_ACTUALITES, payload: null });
  };
}
