export const AUTH_ERROR = "containers/layout/auth_error";
export const AUTH_VERIFICATION = "containers/layout/auth_verification";

export const AUTH_USER = "containers/auth/auth_user";
export const UNAUTH_USER = "containers/auth/unauth_user";

export const AUTH_ACTIVATION = "containers/auth_activation/auth_activation";

export const FETCH_USER = "containers/layout/fetch_user";
export const UPDATE_USER = "containers/layout/update_user";

export const ADD_TO_CART = "containers/cart/add_to_cart";
export const UPDATE_MAX_QUANTITY = "containers/cart/update_max_quantity";
export const UPDATE_CART = "containers/cart/update_cart";
export const SET_INITIAL_CART = "containers/cart/set_initial_cart";
export const CLEAR_CART = "containers/cart/clear_cart";

export const SEARCH = "containers/layout/search";
