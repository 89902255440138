import React, { Component } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Tooltip } from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";

import {
  getCollecte,
  getParticipants,
  getItems,
  getActualites,
  refresh,
} from "./actions";

import { Link } from "react-router-dom";

import "./collecte.scss";
import "../../components/collecte/style.scss";

import Subheader from "../../components/layout/subheader";

// import Participants from '../../components/collecte/participants';
import Stats from "../../components/collecte/stats";
import Description from "../../components/collecte/description";
import Updates from "../../components/collecte/updates";
import Products from "../../components/collecte/products";
import Association from "../../components/association/description";

class CollecteContainer extends Component {
  static propTypes = {
    collecte: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    collecteId: PropTypes.number.isRequired,
    participants: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    items: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    openCart: PropTypes.func.isRequired,
    actualites: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
  };

  static defaultProps = {
    collecte: null,
    collecteId: null,
    participants: null,
    items: null,
    openCart: () => {},
    actualites: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      mobile_selected: 2,
    };
  }

  componentDidMount() {
    this.props.getCollecte(this.props.collecteId);
    this.props.getParticipants(this.props.collecteId);
    this.props.getItems(this.props.collecteId);
    this.props.getActualites(this.props.collecteId);
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  handleMobileSelected = (mobile_selected) => {
    this.setState({ mobile_selected });
  };

  renderMobileMenu = (mobile_selected) => {
    const arr = [
      {
        key: 0,
        value: "Description",
      },
      {
        key: 1,
        value: "Stats",
      },
      {
        key: 2,
        value: "Collecte",
      },
      {
        key: 3,
        value: "Actualités",
      },
      {
        key: 4,
        value: "Association",
      },
    ];

    return arr.map((x) => {
      return (
        <div
          key={x.key}
          className={x.key === mobile_selected ? "item active" : "item"}
          onClick={() => this.handleMobileSelected(x.key)}
        >
          {x.value}
        </div>
      );
    });
  };

  renderMobile = (
    mobile_selected,
    collecte,
    participants,
    items,
    actualites
  ) => {
    switch (mobile_selected) {
      case 0:
        return (
          <div className="block mobile">
            <Description collecte={collecte} color={collecte.theme.couleur} />
          </div>
        );
      case 1:
        return (
          <div className="block mobile" style={{ textAlign: "center" }}>
            <Stats
              collecte={collecte}
              participants={participants.length !== 0 ? participants.length : 0}
            />
          </div>
        );
      case 2:
        return (
          <div className="block mobile">
            <Products
              collecte={collecte}
              color={collecte.theme.couleur}
              addToCart={(data) => this.props.addToCart(data)}
              items={items}
              openCart={() => this.props.openCart()}
            />
          </div>
        );
      case 3:
        return (
          <div className="collecte-updates mobile">
            <Updates color={collecte.theme.couleur} actualites={actualites} />
          </div>
        );
      case 4:
        return (
          <div className="block mobile">
            <Association association={collecte.association} collecte={true}>
              <Link to={`/app/association/${collecte.association.id}`}>
                <div className="en-savoir-plus-association">En savoir plus</div>
              </Link>
            </Association>
          </div>
        );
      default:
        return (
          <div className="block mobile">
            <Description collecte={collecte} color={collecte.theme.couleur} />
          </div>
        );
    }
  };

  renderNotVerified = (association) => {
    if (!association.verified) {
      return (
        <Tooltip
          title="Cela signifie que vous pouvez effectuer vos dons, mais que les informations relatives à l’association n’ont pas encore été vérifiées par nos services"
          placement="bottom"
          arrowPointAtCenter={true}
          color={"#2b8bbc"}
          overlayStyle={{ maxWidth: 350 }}
        >
          <h2 style={{ textAlign: "center", fontWeight: 600 }}>
            Cette collecte est privée <QuestionCircleOutlined />
          </h2>
        </Tooltip>
      );
    }
    return null;
  };

  render() {
    const { collecte, participants, items, actualites } = this.props;
    const { mobile_selected } = this.state;

    return (
      <div>
        <Subheader collecte={collecte} />
        
        {collecte && participants && (
          <div className="collecte-container">
            <Helmet>
              <meta name="og:description" content={collecte.description} />
              <meta name="og:title" content={`Wisdana - ${collecte.nom}`} />
              <meta name="og:image" content={collecte.photo} />
            </Helmet>
            {/* {this.renderNotVerified(collecte.association)} */}

            <div className="mobile-name-collecte">
              {collecte.association.logo !== null ? (
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${collecte.association.logo})`,
                  }}
                ></div>
              ) : null}
              <h2 style={{ color: collecte.theme.couleur }}>
                {collecte.association.nom}
              </h2>
            </div>
            
            {collecte.portal_activated === false ? (
              <div className="mobile-menu-collecte">
                {this.renderMobileMenu(mobile_selected)}
              </div>
            ) : null}

            <div id="0" className="block">
              <Description collecte={collecte} color={collecte.theme.couleur} />
            </div>
            
            {collecte.portal_activated === false ? (
              <div id="1" className="block" style={{ textAlign: "center" }}>
                <Stats
                  collecte={collecte}
                  participants={
                    participants.length !== 0 ? participants.length : 0
                  }
                />
              </div>
            ) : null}

            <div id="2" className="block">
              <Products
                collecte={collecte}
                color={collecte.theme.couleur}
                addToCart={(data) => this.props.addToCart(data)}
                items={items}
                openCart={() => this.props.openCart()}
              />
            </div>
            
            {collecte.portal_activated === false ? (
              <div id="3" className="block">
                <Association association={collecte.association} collecte={true}>
                  <Link to={`/app/association/${collecte.association.id}`}>
                    <div className="en-savoir-plus-association">
                      En savoir plus
                    </div>
                  </Link>
                </Association>
              </div>
            ) : null}

            {this.renderMobile(
              mobile_selected,
              collecte,
              participants,
              items,
              actualites
            )}
          </div>
        )}
        {collecte && (
          <div id="4" className="collecte-updates">
            <Updates color={collecte.theme.couleur} actualites={actualites} />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collecte: state.collecte.collecte,
    participants: state.collecte.participants,
    items: state.collecte.items,
    actualites: state.collecte.actualites,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCollecte,
      getParticipants,
      getItems,
      getActualites,
      refresh,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CollecteContainer);
