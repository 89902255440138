import axios from "axios";
import { message } from "antd";
import Cookies from "js-cookie";

import {
  STRIPE_CLIENT_SECRET,
  STRIPE_CARD,
  CLEAR_CART,
  CREATE_PASSWORD,
  PAYPAL_ORDER,
} from "./types";

import { store } from "../../index";
import API_ROUTES from "../../utils/api_routes";
import { UPDATE_CART, UPDATE_MAX_QUANTITY } from "../layout/types";

const STRIPE_URL = API_ROUTES.stripe;
const STRIPE_NOT_LOGIN_URL = API_ROUTES.stripe_not_login;
const STRIPE_CARD_URL = API_ROUTES.stripe_card;
const CREATE_PASSWORD_URL = API_ROUTES.create_password;
const PAYPAL_ORDER_URL = API_ROUTES.paypal_order;

function transform(data) {
  const items = data.reduce((acc, val) => {
    const object = {
      id: val.article.id,
      quantity: val.quantity,
    };
    if (acc[val.collecte.id]) {
      acc[val.collecte.id].push(object);
    } else {
      acc[val.collecte.id] = [];
      acc[val.collecte.id].push(object);
    }
    return acc;
  }, {});

  return items;
}

function transformPrice(data) {
  const prices = data.reduce((acc, val) => {
    const object = {
      id: val.article.id,
      quantity: val.quantity,
    };

    acc.push(object);
    return acc;
  }, []);

  return prices;
}

export function fetchClientSecret(data) {
  return function (dispatch) {
    const new_data = {
      items: transform(data.cart),
      prices: transformPrice(data.cart),
      ...data,
    };

    axios
      .post(`${STRIPE_URL}`, new_data, {
        headers: {
          Authorization: `JWT ${localStorage.getItem(
            "wisdana-customer-token"
          )}`,
        },
      })
      .then((response) => {
        dispatch({
          type: STRIPE_CLIENT_SECRET,
          payload: response.data.client_secret,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function createCustomerPaypal(data) {
  return function (dispatch) {
    const new_data = {
      items: transform(data.cart),
      prices: transformPrice(data.cart),
      ...data,
    };

    axios
      .post(`${PAYPAL_ORDER_URL}`, new_data, {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          Authorization: `JWT ${localStorage.getItem(
            "wisdana-customer-token"
          )}`,
        },
      })
      .then((response) => {
        dispatch({ type: PAYPAL_ORDER, payload: response.data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
}

export function fetchClientSecretNotLogin(data) {
  return function (dispatch) {
    const new_data = {
      items: transform(data.cart),
      prices: transformPrice(data.cart),
      ...data,
    };

    axios
      .post(`${STRIPE_NOT_LOGIN_URL}`, new_data)
      .then((response) => {
        dispatch({
          type: STRIPE_CLIENT_SECRET,
          payload: response.data.client_secret,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
}

export function fetchCreditCard() {
  return function (dispatch) {
    axios
      .get(`${STRIPE_CARD_URL}`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem(
            "wisdana-customer-token"
          )}`,
        },
      })
      .then((response) => {
        dispatch({ type: STRIPE_CARD, payload: response.data.card });
      })
      .catch((error) => {
        dispatch({ type: STRIPE_CARD, payload: null });
      });
  };
}

export function createPassword(data) {
  return function (dispatch) {
    axios
      .post(`${CREATE_PASSWORD_URL}`, data)
      .then((response) => {
        dispatch({ type: CREATE_PASSWORD, payload: true });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
}

export function flushCartAndCreditCard() {
  return function (dispatch) {
    localStorage.removeItem("cart");
    dispatch({ type: STRIPE_CARD, payload: null });
    dispatch({ type: CLEAR_CART });
    dispatch({ type: STRIPE_CLIENT_SECRET, payload: null });
    dispatch({ type: CREATE_PASSWORD, payload: false });
    dispatch({ type: PAYPAL_ORDER, payload: null });
  };
}

export function updateProductAvailivility() {
  return function (dispatch) {
    const cart = [...store.getState()?.layout?.cart];

    if (!cart?.length) {
      return;
    }

    const payload = cart.map(({ id, quantity }) => ({
      product: id,
      quantity,
    }));

    axios
      .post(`${API_ROUTES.update_product_availivility}`, payload)
      .then((res) => res.data)
      .then((json) => {
        json.forEach(({ product, updated, quantity }) => {
          if (updated) {
            message.warn(
              "La quantité de certains de vos achats ont étés modifiés pour cause de stock insuffisant"
            );
            dispatch({
              type: UPDATE_MAX_QUANTITY,
              payload: { id: product, quantity },
            });
            dispatch({
              type: UPDATE_CART,
              payload: { id: product, quantity },
            });
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
