import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, Select } from 'antd';

const { Option } = Select;

export default class SignupAdresse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adresse: null,
      code_postal: null,
      ville: null,
      pays: 'FR',
    }
  }

  static propTypes = {
    handleStep2: PropTypes.func.isRequired,
  };

  static defaultProps = {
    handleStep2: () => {},
  };

  changeAdresse = (adresse) => {
    this.setState({ adresse });
  }

  changeCodePostal = (code_postal) => {
    this.setState({ code_postal });
  }

  changeVille = (ville) => {
    this.setState({ ville });
  }

  changePays = (pays) => {
    this.setState({ pays });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.handleStep2({
      adresse: this.state.adresse,
      code_postal: this.state.code_postal,
      ville: this.state.ville,
      pays: this.state.pays,
    });
  }

  validateNotEmpty = () => {
    if (this.state.adresse === null) {
      return true;
    }

    if (this.state.code_postal === null) {
      return true;
    } else if (!/^\d{5}$/.test(this.state.code_postal)) {
      return true;
    }

    if (this.state.ville === null) {
      return true;
    }

    if (this.state.pays === null) {
      return true;
    }

    return false;
  }

  validateCodePostal = () => {
    if (this.state.code_postal === null) {
      return null;
    } else if (/^\d{5}$/.test(this.state.code_postal)) {
      return null;
    } else {
      return <p className="formatError">Format: 00000</p>;
    }
  }

  renderCountries = (country) => {
    const countries = [
      {
        value: "UK",
        name: "Angleterre",
      },
      {
        value: "AT",
        name: "Autriche",
      },
      {
        value: "BE",
        name: "Belgique",
      },
      {
        value: "US",
        name: "États Unis",
      },
      {
        value: "CA",
        name: "Canada",
      },
      {
        value: "CN",
        name: "Chine",
      },
      {
        value: "FR",
        name: "France",
      },
      {
        value: "DE",
        name: "Allemagne",
      },
      {
        value: "IT",
        name: "Italie",
      },
      {
        value: "NL",
        name: "Hollande",
      },
      {
        value: "PT",
        name: "Portugal",
      },
      {
        value: "ES",
        name: "Espagne",
      },
      {
        value: "CH",
        name: "Suisse",
      },
    ];
    return (
      <Select
        onChange={(event) => this.changePays(event)}
        value={country}
        bordered={false}
        style={{ width: '100%' }}
      >
        {countries.map((x, index) => {
          return <Option key={index} value={x.value}>{x.name}</Option>;
        })}
      </Select>
    );
  }

  render() {
    return (
      <form onSubmit={e => this.handleSubmit(e)}>
        <div className="div-input long">
          <label>Quelle est votre adresse ? <span>(nécessaire pour les reçus fiscaux)</span></label>
          <Input
            placeholder=""
            name="adresse"
            value={this.state.adresse}
            onChange={(e) => this.changeAdresse(e.target.value)}
          />
        </div>
        <div className="multiple-input">
          <div className="div-input">
            <label>Votre code postal</label>
            <Input
              placeholder=""
              name="code_postal"
              value={this.state.code_postal}
              onChange={(e) => this.changeCodePostal(e.target.value)}
            />
            {this.validateCodePostal()}
          </div>
          <div className="div-input">
            <label>Votre ville</label>
            <Input
              placeholder=""
              name="ville"
              value={this.state.ville}
              onChange={(e) => this.changeVille(e.target.value)}
            />
          </div>
        </div>
        <div className="div-input long">
          <label>et votre pays ?</label>
          <div className="ant-input" style={{ display: 'flex', alignItems: 'center' }}>
            <div id="country" className={`${this.state.pays.toLowerCase()}`} style={{ height: 16 }}>
            </div>
            {this.renderCountries(this.state.pays)}
          </div>
        </div>
        <div className="submit">
          <div className="skip-part" onClick={() => this.handleSubmit()}>Ignorer cette étape</div>
        </div>
        <div className="submit">
          <button type="submit" disabled={this.validateNotEmpty() ? true:false}>S'enregistrer</button>
        </div>
      </form>
    );
  }
}
