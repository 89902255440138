import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jwt from "jsonwebtoken";

import Collecte from "../collecte/collecteContainer";
import Feed from "../feed/feedContainer";
import Dashboard from "../dashboard/dashboardContainer";
import Checkout from "../checkout/checkoutContainer";
import Association from "../association/associationContainer";

import Header from "../../components/layout/header";
import Subheader from "../../components/layout/subheader";
import Search from "../../components/search/search";

import {
  refreshToken,
  verifyToken,
  getUser,
  refresh,
  addToCart,
  updateCart,
  searchFunction,
  refreshSearch,
} from "./actions";

import "../../components/layout/style.scss";

class LayoutContainer extends Component {
  static propTypes = {
    verify: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    authenticated: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    user: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    search: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    verify: null,
    error: null,
    user: null,
    authenticated: null,
    search: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      open_cart: false,
    };
  }

  componentDidMount() {
    this.props.verifyToken();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verify === null) {
      if (
        localStorage.getItem("token") &&
        jwt.decode(localStorage.getItem("token"))
      ) {
        const expiry = new Date(
          jwt.decode(localStorage.getItem("token")).exp * 1000
        );
        const now = new Date();
        if (now.getDate() - expiry.getDate() === 1) {
          this.props.refreshToken();
        }
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.refreshSearch();
    }

    if (
      prevProps.cart !== this.props.cart &&
      prevProps.cart !== null &&
      this.state.open_cart === false &&
      this.props.cart.length > 0
    ) {
      this.setState({ open_cart: true });
    }
    return true;
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  addToCart = (data, cart) => {
    let added = false;

    const newdata = cart.map((x) => {
      if (x.id === data.id) {
        if (x.quantity + data.quantity <= data.quantity_available) {
          data.quantity = x.quantity + data.quantity;
        }

        added = true;
        return data;
      }
      return x;
    });

    if (!added) {
      newdata.push(data);
    }

    this.props.addToCart(newdata);
  };

  closeCart = () => {
    this.setState({ open_cart: false });
  };

  openCart = () => {
    this.setState({ open_cart: true });
  };

  render() {
    const { verify, authenticated, user, location, cart, search } = this.props;

    if (verify && authenticated && user === null) {
      this.props.getUser();
    }

    if (search !== null) {
      return (
        <Header
          user={user !== null ? user.user : null}
          cart={cart}
          updateCart={(data) => this.props.updateCart(data)}
          open_cart={this.state.open_cart}
          closeCart={() => this.closeCart()}
          openCart={() => this.openCart()}
          location={this.props.location.pathname}
          search={(data) => this.props.searchFunction(data)}
        >
          <Search
            refreshSearch={() => this.props.refreshSearch()}
            search={search}
          />
        </Header>
      );
    }

    if (location.pathname === "/app/checkout") {
      if (authenticated && user !== null) {
        return (
          <Route path="/app/checkout" exact={true}>
            <Checkout user={user} cart={cart} />
          </Route>
        );
      }
      return (
        <Route path="/app/checkout" exact={true}>
          <Checkout user={user} cart={cart} />
        </Route>
      );
    }

    if (authenticated) {
      if (user !== null) {
        return (
          <Header
            user={user.user}
            cart={cart}
            updateCart={(data) => this.props.updateCart(data)}
            open_cart={this.state.open_cart}
            closeCart={() => this.closeCart()}
            openCart={() => this.openCart()}
            location={this.props.location.pathname}
            search={(data) => this.props.searchFunction(data)}
          >
            <Route path="/app/dashboard" exact={true}>
              <Subheader />
              <Dashboard user={user} />
            </Route>
            <Route path="/app/feed" exact={true}>
              <Feed user={user} />
            </Route>
            <Route path="/app/reset-password" exact={true}>
              Yolo 1234231
            </Route>
            <Route
              path="/app/collecte/:id"
              render={({ match }) => (
                <Collecte
                  addToCart={(data) => this.addToCart(data, cart)}
                  collecteId={match.params.id}
                  openCart={() => this.openCart()}
                />
              )}
            />
            <Route
              path="/app/association/:id"
              render={({ match }) => (
                <Association
                  user={user}
                  associationId={parseInt(match.params.id)}
                />
              )}
            />
          </Header>
        );
      }
      return (
        <Header
          user={null}
          cart={cart}
          updateCart={(data) => this.props.updateCart(data)}
          open_cart={this.state.open_cart}
          closeCart={() => this.closeCart()}
          openCart={() => this.openCart()}
          location={this.props.location.pathname}
          search={(data) => this.props.searchFunction(data)}
        >
          <Feed user={null} />
        </Header>
      );
    }

    return (
      <Header
        user={null}
        cart={cart}
        updateCart={(data) => this.props.updateCart(data)}
        open_cart={this.state.open_cart}
        closeCart={() => this.closeCart()}
        openCart={() => this.openCart()}
        location={this.props.location.pathname}
        search={(data) => this.props.searchFunction(data)}
      >
        <Route path="/app/dashboard" exact={true}>
          <Redirect to={`/app/login?url=${location.pathname}`} />
        </Route>
        <Route path="/app/feed" exact={true}>
          <Feed user={null} />
        </Route>
        <Route
          path="/app/collecte/:id"
          render={({ match }) => (
            <Collecte
              addToCart={(data) => this.addToCart(data, cart)}
              collecteId={match.params.id}
              openCart={() => this.openCart()}
            />
          )}
        />
        <Route
          path="/app/association/:id"
          render={({ match }) => (
            <Association
              user={user}
              associationId={parseInt(match.params.id)}
            />
          )}
        />
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    verify: state.layout.verify,
    error: state.layout.error,
    authenticated: state.auth.authenticated,
    user: state.layout.user,
    cart: state.layout.cart,
    search: state.layout.search,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addToCart,
      refreshToken,
      verifyToken,
      getUser,
      refresh,
      updateCart,
      searchFunction,
      refreshSearch,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
