import React, { Component } from "react";
import PropTypes from "prop-types";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

import CardSetupForm from "./cardSetupForm";
import CardPresent from "./cardPresent";

import Paypal from "./paypal";
import env from "../../config/env";

const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

class Cart extends Component {
  static propTypes = {
    cart: PropTypes.array.isRequired,
    client_secret: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    user: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    card: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    redirectToDashboard: PropTypes.func.isRequired,
    fetchClientSecretNotLogin: PropTypes.func.isRequired,
    fetchClientSecret: PropTypes.func.isRequired,
    createCustomerPaypal: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cart: [],
    client_secret: null,
    user: null,
    card: null,
    redirectToDashboard: () => {},
    fetchClientSecretNotLogin: () => {},
    fetchClientSecret: () => {},
    createCustomerPaypal: () => {},
    goBack: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      card_present: false,
      new_card: false,
    };
  }

  componentDidMount() {
    if (this.props.card) {
      this.setState({ card_present: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.card === null && this.props.card !== null) {
      this.setState({ card_present: true });
    }
  }

  handleCardPresent = () => {
    this.setState({ card_present: !this.state.card_present });
  };

  toggleNewCard = () => {
    this.setState({ new_card: !this.state.new_card });
  };

  render() {
    const { client_secret, user, card, cart } = this.props;
    const { card_present, new_card } = this.state;

    if (card_present) {
      return (
        <div>
          <div className="element-container">
            <div className="paypal-container">
              <h2>Quel mode de paiement choisissez-vous ?</h2>
              <p>Toutes les transactions sont chiffrées et securisés</p>
            </div>
            <Paypal
              user={user}
              info={false}
              cart={cart}
              createCustomer={(data) => this.props.createCustomerPaypal(data)}
            />
            <div className="ou-block">
              <div className="ou-block-line">
                <hr />
              </div>
              <div className="ou"> ou </div>
              <div className="ou-block-line">
                <hr />
              </div>
            </div>
            {!new_card && (
              <Elements stripe={stripePromise}>
                <CardPresent
                  client_secret={client_secret}
                  card={card}
                  cart={cart}
                  user={user}
                  redirectToDashboard={(email) =>
                    this.props.redirectToDashboard(email)
                  }
                >
                  <div
                    className="cta-toggle-card-present alternate"
                    onClick={this.toggleNewCard}
                  >
                    <PlusOutlined />
                    Ajouter un nouveau moyen de paiement
                  </div>
                </CardPresent>
              </Elements>
            )}
            {new_card && (
              <Elements stripe={stripePromise}>
                <CardSetupForm
                  client_secret={client_secret}
                  user={user}
                  cart={cart}
                  redirectToDashboard={(email) =>
                    this.props.redirectToDashboard(email)
                  }
                  fetchClientSecretNotLogin={(data) =>
                    this.props.fetchClientSecretNotLogin(data)
                  }
                  fetchClientSecret={(data) =>
                    this.props.fetchClientSecret(data)
                  }
                >
                  <div
                    className="cta-toggle-card-present alternate"
                    onClick={this.toggleNewCard}
                  >
                    <PlusOutlined />
                    Retourner à ma carte enregistrée
                  </div>
                </CardSetupForm>
              </Elements>
            )}
          </div>
          <div
            className="cta-toggle-card-present alternate annuler"
            onClick={this.props.goBack}
          >
            <CloseOutlined />
            Annuler
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="element-container">
          {user === null && (
            <p style={{ fontWeight: 200 }}>
              J'ai un compte Wisdana:{" "}
              <Link
                to="/app/login?url=/app/checkout"
                style={{ fontWeight: 600 }}
              >
                m'identifier
              </Link>
            </p>
          )}
          <Paypal
            user={user}
            cart={cart}
            info={true}
            createCustomer={(data) => this.props.createCustomerPaypal(data)}
          />
          <div className="ou-block">
            <div className="ou-block-line">
              <hr />
            </div>
            <div className="ou"> ou </div>
            <div className="ou-block-line">
              <hr />
            </div>
          </div>
          <Elements stripe={stripePromise}>
            <CardSetupForm
              client_secret={client_secret}
              user={user}
              cart={cart}
              redirectToDashboard={(email) =>
                this.props.redirectToDashboard(email)
              }
              fetchClientSecretNotLogin={(data) =>
                this.props.fetchClientSecretNotLogin(data)
              }
              fetchClientSecret={(data) =>
                this.props.fetchClientSecret({ ...data, first_time: true })
              }
            />
          </Elements>
        </div>
        <div
          className="cta-toggle-card-present alternate annuler"
          onClick={this.props.goBack}
        >
          <CloseOutlined />
          Annuler
        </div>
      </div>
    );
  }
}

export default Cart;
