const envs = {
  production: {
    API_HOST: "https://api.wisdana.com/api",
    PUBLIC_URL: "https://app.wisdana.com",
    ASSOCIATION_URL: "https://association.wisdana.com",
    CONTACT_EMAIL: "alexandre@wisdana.com",
    ROOT_DOMAIN: "wisdana.com",
    STATIC_URL: "https://info.wisdana.com",
    TEAM_URL: "https://equipe.wisdana.com",
    PAYPAL_PUBLIC_KEY:
      "Ad0P2KSsT2m5q4ewn_Jtu32S55IMvkvcW-7FsM4TGDZ56jxKY7KKZBRYArcD9az5Co29rXgiE7x_fa-2",
    STRIPE_PUBLIC_KEY:
      "pk_live_51JPEt4Khirt2Lz4ICKmFLTdm6hFnZUf3BC86WyjPebn2mWBNBNNSDAu1mRUyVGxHKT6aiUZbk02NXpwxqQ6T4IwO00Solfy7pI",
  },

  development: {
    // API_HOST: "http://localhost:8000/api",
    API_HOST: "https://api.sharingsharing.com/api",
    ASSOCIATION_URL: "https://association.sharingsharing.com",
    CONTACT_EMAIL: "alexandre@sharingsharing.com",
    PUBLIC_URL: "https://app.sharingsharing.com",
    ROOT_DOMAIN: "sharingsharing.com",
    STATIC_URL: "https://info.wisdana.com",
    TEAM_URL: "https://equipe.sharingsharing.com",
    PAYPAL_PUBLIC_KEY:
      "ATP02yjkqsoHKoWgYr3BmH1QUXQpfPj-7FeELVSLSqEkacTalQOyOryD7LEwBNsm9or_CwfxnCsCXayq",
    STRIPE_PUBLIC_KEY:
      "pk_test_51JPEt4Khirt2Lz4IVr2nxgpyt22oBN3bjOnM4lotgbLn8Tlp5T2YbJ1GxawYuQ9IgCYRq9y9vzXHO1BTPjQ4XJMA00yiyRNY42",
  },
};

export default envs[process.env.REACT_APP_ENV || "development"];
