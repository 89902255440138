import React, { Component } from "react";
import PropTypes from "prop-types";

import Image from "../elements/image";

import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { trackWindowScroll } from "react-lazy-load-image-component";

class Products extends Component {
  static propTypes = {
    orders: PropTypes.object.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    orders: {},
    scrollPosition: {},
  };

  calculatePrice = (quantity, price) => {
    const returned_price = quantity * parseFloat(price);
    return returned_price.toFixed(2);
  };

  calculateTotalPrice = (orders) => {
    const price = orders.reduce((acc, val) => {
      acc = acc + val.quantity * parseFloat(val.price);
      return acc;
    }, 0);

    return price.toFixed(2);
  };

  calculateTotalPriceWithService = (data, frais_service) => {
    frais_service = 0;
    const price = data.reduce((acc, val) => {
      acc = acc + val.quantity * parseFloat(val.price);
      return acc;
    }, 0);

    return (parseFloat(price) + parseFloat(frais_service / 100)).toFixed(2);
  };

  getTaxes = (orders, frais_service) => {
    frais_service = 0;
    const price =
      (this.calculateTotalPriceWithService(orders, frais_service) / 1.2) * 0.2;
    return price.toFixed(2);
  };

  getDefisc = (orders) => {
    const price = orders.items.reduce((acc, val) => {
      const price = val.quantity * parseFloat(val.price_after_defiscalisation);
      return price + acc;
    }, 0.0);

    return price.toFixed(2);
  };

  truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  renderCart = (data, scrollPosition) => {
    const x = data;
    return data.items.map((y) => {
      const { couleur } = x.collecte.theme;

      return (
        <div key={y.article.id} className="cart-item">
          <div className="cart-item-photo">
            <Image
              image={{
                src: y.article.photo,
                alt: y.article.nom,
                height: 130,
              }}
              scrollPosition={scrollPosition}
            />
          </div>
          <div className="cart-item-body">
            <div className="cart-item-body-top">
              <p className="cart-item-body-top-nom">{y.article.nom}</p>
              <p className="cart-item-body-top-collecte">
                Collecte{" "}
                <span style={{ fontWeight: "bold", color: couleur }}>
                  {this.truncate(x.collecte.nom, 27)}
                </span>
              </p>
            </div>
            <div className="cart-item-body-bottom">
              <div>
                <p
                  className="cart-item-body-bottom-prix"
                  style={{ textAlign: "left" }}
                >
                  Quantité:{" "}
                  <span style={{ fontWeight: 500 }}>{y.quantity}</span>
                </p>
                <p
                  className="cart-item-body-bottom-prix"
                  style={{ textAlign: "left" }}
                >
                  Prix unit. {y.price}€
                </p>
                {y.livraison !== null ? (
                  <p className="cart-item-body-bottom-livraison">
                    Article Livré
                  </p>
                ) : (
                  <p className="cart-item-body-bottom-livraison not-delivered">
                    Prévu dans la prochaine livraison
                  </p>
                )}
              </div>
              <h2
                className="cart-item-price mobile"
                style={{ fontWeight: 500 }}
              >
                {this.calculatePrice(y.quantity, y.price)}€
              </h2>
            </div>
          </div>
          <h2 className="cart-item-price" style={{ fontWeight: 500 }}>
            {this.calculatePrice(y.quantity, y.price)}€
          </h2>
        </div>
      );
    });
  };

  render() {
    const { orders, scrollPosition } = this.props;

    return (
      <div className="cart-block">
        {this.renderCart(orders, scrollPosition)}
        <div className="cart-total">
          <div className="left-part">
            {/* <h5>
              Frais de fonctionnement Wisdana{" "}
              <Tooltip
                title={
                  <span style={{ textAlign: "center" }}>
                    Pour continuer à proposer ce service gratuitement aux
                    associations, nous avons besoin de votre aide avec une
                    petite contribution pour les frais bancaires et nos frais de
                    fonctionnement.
                  </span>
                }
                placement="topRight"
                color={"#2b8bbc"}
                overlayClassName="tooltip-defisc"
                overlayStyle={{ maxWidth: 250 }}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </h5> */}
            <h5>Taxes (TVA) et livraison incluses</h5>
            <h3>Total</h3>
          </div>
          <div className="right-part">
            {/* <h5>{parseFloat(orders.frais_service / 100).toFixed(2)}€</h5> */}
            <h5>{this.getTaxes(orders.items, orders.frais_service)}€</h5>
            <h2>
              {this.calculateTotalPriceWithService(
                orders.items,
                orders.frais_service
              )}
              €
            </h2>
            <h4>
              soit{" "}
              <span style={{ fontWeight: "bold", color: "#2b8bbc" }}>
                {this.getDefisc(orders)}€
              </span>{" "}
              après
              <br />
              défiscalisation
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default trackWindowScroll(Products);
