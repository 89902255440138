import axios from 'axios';

import {
	RANKINGS_CUSTOMER,
	ORDERS_CUSTOMER,
	PATCH_NEW_PASSWORD,
	UPDATE_USER,
	UPDATE_USER_DASHBOARD,
	GET_RECU_FISCAL,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const RANKINGS_URL = API_ROUTES.rankings;
const ORDERS_URL = API_ROUTES.orders;
const UPDATE_PASSWORD_URL = API_ROUTES.update_password;
const UPDATE_USER_URL = API_ROUTES.customer;

export function getRankings() {
	return function(dispatch) {
		axios.get(`${RANKINGS_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }
    })
			.then(response => {
				dispatch({ type: RANKINGS_CUSTOMER, payload: response.data });
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	}
}

export function getOrders() {
	return function (dispatch) {
		axios.get(`${ORDERS_URL}`, {
			headers: { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }
		})
		.then(response => {
			dispatch({ type: ORDERS_CUSTOMER, payload: response.data });
		})
		.catch(error => {
			console.log(error.response.data);
		});
	}
}

export function patchNewPassword(data) {
	return function (dispatch) {
		axios.patch(`${UPDATE_PASSWORD_URL}`, data, {
			headers: { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }
		})
		.then(response => {
			localStorage.removeItem('wisdana-customer-token');
			localStorage.setItem('wisdana-customer-token', response.data.token);
			dispatch({ type: PATCH_NEW_PASSWORD, success: true });
		})
		.catch(error => {
			console.log(error.response.data);
			dispatch({ type: PATCH_NEW_PASSWORD, success: false, error: error.response.data.error });
		});
	}
}

export function updateUser(data) {
	return function (dispatch) {
		axios.patch(`${UPDATE_USER_URL}`, data, {
			headers: { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }
		})
		.then(response => {
			dispatch({ type: UPDATE_USER, payload: response.data });
			dispatch({ type: UPDATE_USER_DASHBOARD, success: true });
		})
		.catch(error => {
			console.log(error.response.data);
			dispatch({ type: UPDATE_USER_DASHBOARD, success: false, error: error.response.data.error });
		});
	}
}

export function getRecuFiscalLink(id) {
	return function(dispatch) {
		axios.get(`${ORDERS_URL}${id}/recu-fiscal/`, {
			headers: { Authorization: `JWT ${localStorage.getItem('wisdana-customer-token')}` }
		})
		.then(response => {
			dispatch({ type: GET_RECU_FISCAL, payload: response.data });
		})
		.catch(error => {
			console.log(error.response.data);
			dispatch({ type: GET_RECU_FISCAL, payload: 'error' });
		});
	}
}

export function cleanRecuFiscal() {
	return function(dispatch) {
		dispatch({ type: GET_RECU_FISCAL, payload: null });
	}
}

export function flushSuccess() {
	return function (dispatch) {
		dispatch({ type: UPDATE_USER_DASHBOARD, success: null });
	}
}
