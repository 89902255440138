import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import Image from "../elements/image";

import { message, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { trackWindowScroll } from "react-lazy-load-image-component";

class Cart extends Component {
  static propTypes = {
    cart: PropTypes.array.isRequired,
    updateCart: PropTypes.func,
    onClose: PropTypes.func,
    in_checkout: PropTypes.bool.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    cart: [],
    updateCart: () => {},
    onClose: () => {},
    scrollPosition: null,
    in_checkout: false,
  };

  calculatePrice = (quantity, price) => {
    const returned_price = quantity * parseFloat(price);
    return returned_price.toFixed(2);
  };

  calculateTotalPrice = (data) => {
    const price = data.reduce((acc, val) => {
      const price_with_heavy = val.article.is_heavy
        ? parseFloat(val.price) + 2.0
        : val.price;
      acc = acc + val.quantity * parseFloat(price_with_heavy);
      return acc;
    }, 0);

    return price.toFixed(2);
  };

  calculateTotalPriceWithService = (data) => {
    const price = data.reduce((acc, val) => {
      const price_with_heavy = val.price;
      // val.article.is_heavy
      // ? parseFloat(val.price) + 2.0
      // : val.price;
      acc = acc + val.quantity * parseFloat(price_with_heavy);
      return acc;
    }, 0);

    return price.toFixed(2);
    // if (price > 20.0) {
    //   return ((price * 106) / 100).toFixed(2);
    // } else {
    //   return (price + 1.2).toFixed(2);
    // }
  };

  getProduitLourd = (cart) => {
    const price = cart.reduce((acc, val) => {
      // if (val.article.is_heavy) {
      //   acc += 2.0;
      // }
      return acc;
    }, 0.0);
    return price.toFixed(2);
  };

  getTaxes = (cart) => {
    const price = (this.calculateTotalPriceWithService(cart) / 1.2) * 0.2;
    return price.toFixed(2);
  };

  getFraisService = (cart) => {
    return 0;
    // const price = this.calculateTotalPrice(cart);
    // if (price > 20.0) {
    //   return ((price * 6) / 100).toFixed(2);
    // } else {
    //   return "1.20";
    // }
  };

  getDefisc = (cart) => {
    const price = cart.reduce((acc, val) => {
      const price_with_heavy = val.price_after_defiscalisation;
      // val.article.is_heavy
      //   ? parseFloat(val.price_after_defiscalisation) + 2.0
      //   : val.price_after_defiscalisation;
      return acc + val.quantity * parseFloat(price_with_heavy);
    }, 0.0);

    return price.toFixed(2);
  };

  decrement = (e, quantity, id) => {
    e.preventDefault();
    if (quantity === 1) {
      message.warning("Produit retiré du panier 😥");
    }

    if (quantity > 0) {
      const obj = {
        quantity: quantity - 1,
        id,
      };
      this.props.updateCart(obj);
    }
  };

  increment = (e, quantity, quantity_available, id) => {
    e.preventDefault();
    if (quantity + 1 <= quantity_available) {
      const obj = {
        quantity: quantity + 1,
        id,
      };
      this.props.updateCart(obj);
    } else {
      message.warning("Quantité maximale atteinte 😎");
    }
  };

  truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  renderWarning = () => {
    message.error("Pour procéder au paiement, un minimum de 6€20 est requis");
  };

  renderCart = (data, scrollPosition) => {
    const { in_checkout } = this.props;
    return data.map((x) => {
      const { couleur } = x.collecte.theme;

      const price = parseFloat(x.price);
      //  x.article.is_heavy
      //   ? parseFloat(x.price) + 2.0
      //   : parseFloat(x.price);

      return (
        <div key={x.article.id} className="cart-item">
          <div className="cart-item-photo">
            <Image
              image={{
                src: x.article.photo,
                alt: x.article.nom,
                height: 130,
              }}
              scrollPosition={scrollPosition}
            />
          </div>
          <div className="cart-item-body">
            <div className="cart-item-body-top">
              <p className="cart-item-body-top-nom">
                {this.truncate(x.article.nom, 31)}
              </p>
              <p className="cart-item-body-top-collecte">
                Collecte{" "}
                {!in_checkout && (
                  <Link to={`/app/collecte/${x.collecte.code_url}`}>
                    <span style={{ fontWeight: "bold", color: couleur }}>
                      {this.truncate(x.collecte.nom, 27)}
                    </span>
                  </Link>
                )}
                {in_checkout && (
                  <span style={{ fontWeight: "bold", color: couleur }}>
                    {this.truncate(x.collecte.nom, 27)}
                  </span>
                )}
              </p>
            </div>
            <div className="cart-item-body-bottom">
              <div>
                {x.article.is_heavy && (
                  <p
                    className="cart-item-body-bottom-prix"
                    style={{ fontSize: 11 }}
                  >
                    <i
                      style={{ color: "#E1E1E1" }}
                      className="fad fa-weight-hanging"
                    ></i>{" "}
                    Supp. produit lourd 2.00€
                  </p>
                )}
                <p className="cart-item-body-bottom-prix">
                  Prix unit. {x.price}€
                </p>
                {!in_checkout && (
                  <div className="number-input">
                    <button
                      onClick={(e) => this.decrement(e, x.quantity, x.id)}
                    ></button>
                    <span>{x.quantity}</span>
                    <button
                      onClick={(e) =>
                        this.increment(
                          e,
                          x.quantity,
                          x.quantity_available,
                          x.id
                        )
                      }
                      className="plus"
                    ></button>
                  </div>
                )}
              </div>
              <h2
                className="cart-item-price mobile"
                style={{ fontWeight: 500 }}
              >
                {this.calculatePrice(x.quantity, price)}€
              </h2>
            </div>
          </div>
          <h2 className="cart-item-price" style={{ fontWeight: 500 }}>
            {this.calculatePrice(x.quantity, price)}€
          </h2>
        </div>
      );
    });
  };

  render() {
    const { cart, scrollPosition } = this.props;

    return (
      <div className="cart-block">
        <div className="cart-block-render">
          {this.renderCart(cart, scrollPosition)}
        </div>

        {cart.length > 0 && (
          <div className="cart-total">
            <div className="left-part">
              {/* <h5>
                Frais fonctionnement Wisdana{" "}
                <Tooltip
                  title={
                    <span style={{ textAlign: "center" }}>
                      Pour continuer à proposer ce service gratuitement aux
                      associations, nous avons besoin de votre aide avec une
                      petite contribution pour les frais bancaires et nos frais
                      de fonctionnement.
                    </span>
                  }
                  placement="topRight"
                  color={"#2b8bbc"}
                  overlayClassName="tooltip-defisc"
                  overlayStyle={{ maxWidth: 250 }}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </h5> */}
              <h3>Total</h3>
              <h5 style={{ color: "rgba(0,0,0,0.85)" }}>Dont taxes (TVA)</h5>
            </div>
            <div className="right-part">
              {/* <h5>{this.getFraisService(cart)}€</h5> */}
              <h2>{this.calculateTotalPriceWithService(cart)}€</h2>
              <h4 style={{ color: "rgba(0,0,0,0.85)" }}>
                {this.getTaxes(cart)}€
              </h4>
              <h4>
                <Tooltip
                  title={
                    <span style={{ textAlign: "center" }}>
                      Après avoir déclaré vos dons aux impôts, ce montant
                      correspond à ce que vous aurez réellement payé. Pour plus
                      d’informations, consultez la rubrique Fiscalité de notre
                      FAQ.
                    </span>
                  }
                  placement="bottomRight"
                  color={"#2b8bbc"}
                  overlayClassName="tooltip-defisc"
                  overlayStyle={{ maxWidth: 350 }}
                >
                  soit{" "}
                  <span style={{ fontWeight: "bold", color: "#2b8bbc" }}>
                    {this.getDefisc(cart)}€
                  </span>{" "}
                  après
                  <br />
                  défiscalisation <QuestionCircleOutlined />
                </Tooltip>
              </h4>
            </div>
          </div>
        )}
        {cart.length > 0 && this.calculateTotalPrice(cart) < 5.0 && (
          <div className="cart-cta" onClick={() => this.renderWarning()}>
            <p>Valider mes dons</p>
          </div>
        )}
        {cart.length > 0 && this.calculateTotalPrice(cart) >= 5.0 && (
          <Link to={"/app/checkout"} onClick={() => this.props.onClose()}>
            <div className="cart-cta">
              <p>Valider mes dons</p>
            </div>
          </Link>
        )}
        {cart.length > 0 && (
          <p className="petite-phrase">
            après le paiement, vos dons seront livrés directement aux
            associations concernées
          </p>
        )}
      </div>
    );
  }
}

export default trackWindowScroll(Cart);
