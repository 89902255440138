import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import UpdateCard from '../elements/updateCard';

export default class Updates extends Component {
  static propTypes = {
    actualites: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ])
  }

  static defaultProps = {
    actualites: [],
  }

  renderUpdates = (updates, color) => {
    return updates.map(x => {
      return (
        <Timeline.Item
          dot={<ClockCircleOutlined style={{ fontSize: 24, color: color }} />}
          key={x.id}
        >
          <UpdateCard
            data={x}
            color={color}
          />
        </Timeline.Item>
      );
    });
  }

  render() {
    const { actualites, color } = this.props;

    if (actualites === null) {
      return null;
    }

    return (
      <div className="association-update">
        <Timeline>
          {this.renderUpdates(actualites, color)}
        </Timeline>
      </div>
    );
  }
}
