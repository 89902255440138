import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import Bandeau from './bandeau';
import Bandeau from './bandeau_alternate';
import Feed from './feed';

export default class CollecteBlock extends Component {
  static propTypes = {
    feed: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    theme: PropTypes.object.isRequired,
    sousTheme: PropTypes.array.isRequired,
    onSelectSousTheme: PropTypes.func.isRequired,
  };

  onSelectSousTheme = (sousTheme) => {
    this.props.onSelectSousTheme(sousTheme);
  }

  render() {
    const { feed, theme, sousTheme } = this.props;

    return (
      <div className="collecteBlockDiv">
        <div className="collecteDiv">
          <div className="collectBox">
            {/*<Bandeau
              theme={theme}
              principal={true}
              sousTheme={sousTheme}
              onSelectSousTheme={(item) => this.onSelectSousTheme(item)}
            />*/}
            <div className="bandeau-box">
              <Bandeau
                theme={theme}
              />
              <h3>Faites un don en nature en achetant directement ce dont les animaux ont besoin !</h3>
            </div>
            {feed.length === 0 &&
              <h3 style={{ textAlign: 'center', margin: '50px 0px', fontWeight: '700' }}>Il n'y a pas encore de collectes publiques pour cette catégorie.</h3>
            }
            {feed.length !== 0 &&
              <Feed
                feed={feed !== null && feed}
                sousTheme={sousTheme}
                color={theme.couleur}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}
