import axios from "axios";

import { GET_THEMES, GET_FEED } from "./types";

import API_ROUTES from "../../utils/api_routes";

const THEMES_URL = API_ROUTES.themes;
const FEED_URL = API_ROUTES.feed;

const headers =
  localStorage.getItem("wisdana-customer-token") !== null
    ? { Authorization: `JWT ${localStorage.getItem("wisdana-customer-token")}` }
    : {};

export function getThemes() {
  return function (dispatch) {
    axios
      .get(`${THEMES_URL}`, {
        headers,
      })
      .then((response) => {
        dispatch({ type: GET_THEMES, themes: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
}

export function getFeed(id) {
  return function (dispatch) {
    dispatch({ type: GET_FEED, feed: null });
    axios
      .get(`${FEED_URL}${id}/?landing_mode`, {
        headers,
      })
      .then((response) => {
        dispatch({ type: GET_FEED, feed: response.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
}
