import React, { Component } from "react";

import { ElementsConsumer } from "@stripe/react-stripe-js";

import Amex from "../../resources/img/Amex_logo_color.svg";
import MC from "../../resources/img/mc_symbol.svg";
import Visa from "../../resources/img/visa_logo.svg";

class CardPresent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      error: null,
    };
  }

  componentWillUnmount() {
    this.setState({ processing: false, error: null });
  }

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    this.setState({ processing: true });

    const { stripe, elements, client_secret, card } = this.props;

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: card.id,
      setup_future_usage: "off_session",
    });

    if (result.error) {
      // Display result.error.message in your UI.
      this.setState({ error: result.error.message, processing: false });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        this.props.redirectToDashboard(null);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  getLogo = (name) => {
    switch (name) {
      case "visa":
        return <img src={Visa} height={40} width={40} alt="visa" />;
      case "mastercard":
        return <img src={MC} height={40} width={40} alt="mc" />;
      case "amex":
        return <img src={Amex} height={40} width={40} alt="amex" />;
      default:
        return null;
    }
  };

  getTotal = (data) => {
    const price = data.reduce((acc, val) => {
      const price_with_heavy = val.price;
      // val.article.is_heavy
      //   ? parseFloat(val.price) + 2.0
      //   : val.price;
      acc = acc + val.quantity * parseFloat(price_with_heavy);
      return acc;
    }, 0.0);

    return price.toFixed(2);
    // if (price > 20.00) {
    //   return (price * 106 / 100).toFixed(2);
    // } else {
    //   return (price + 1.20).toFixed(2);
    // }
  };

  render() {
    const { card, cart, stripe, children } = this.props;
    const { processing, error } = this.state;

    if (!stripe) {
      return null;
    }

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <section>
          <h2>Paiement</h2>
          <fieldset>
            <label>
              <span>Carte</span>
              <div className="card-present-element">
                <div className="logo">{this.getLogo(card.brand)}</div>
                <div className="card">XXXX XXXX XXXX {card.last4}</div>
                <div className="card mobile">XXXX {card.last4}</div>
                <div className="expiry">
                  {card.exp_month}/{card.exp_year}
                </div>
              </div>
            </label>
          </fieldset>
        </section>
        {children}
        <button
          className="payment-button"
          onClick={this.handleSubmit}
          disabled={processing}
        >
          {processing ? "En cours" : `Payer €${this.getTotal(cart)}`}
        </button>
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
      </div>
    );
  }
}

export default function InjectedCardSetupForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardPresent
          stripe={stripe}
          elements={elements}
          card={props.card}
          cart={props.cart}
          client_secret={props.client_secret}
          redirectToDashboard={() => props.redirectToDashboard()}
          children={props.children}
        />
      )}
    </ElementsConsumer>
  );
}
