import {
	RANKINGS_CUSTOMER,
	PATCH_NEW_PASSWORD,
	ORDERS_CUSTOMER,
	UPDATE_USER_DASHBOARD,
	GET_RECU_FISCAL,
} from './types';

const initialState = {
  rankings: null,
	error: null,
	orders: null,
	success: null,
	recu_fiscal: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case RANKINGS_CUSTOMER:
			return { ...state, rankings: action.payload };
		case PATCH_NEW_PASSWORD:
			return { ...state, error: action.error, success: action.success };
		case ORDERS_CUSTOMER:
			return { ...state, orders: action.payload };
		case UPDATE_USER_DASHBOARD:
			return { ...state, error: action.error, success: action.success };
		case GET_RECU_FISCAL:
			return { ...state, recu_fiscal: action.payload };
		default:
			return { ...state };
	}
}
