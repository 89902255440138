import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Input, Drawer } from "antd";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import Logo from "../../resources/img/logo_white.svg";
import HeaderTopBG from "../../resources/img/header_top_bg.png";

import Cart from "./cart";
import Footer from "./footer";

import "./style.scss";

import env from "../../config/env";
const { ASSOCIATION_URL, STATIC_URL } = env;

// const { SubMenu } = Menu;
const { Search } = Input;

export default class Header extends Component {
  static propTypes = {
    user: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }).isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    cart: PropTypes.array.isRequired,
    updateCart: PropTypes.func.isRequired,
    open_cart: PropTypes.bool.isRequired,
    closeCart: PropTypes.func.isRequired,
    openCart: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  };

  static defaultProps = {
    user: {},
    cart: [],
    open_cart: false,
    updateCart: () => {},
    closeCart: () => {},
    openCart: () => {},
    location: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      active: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    switch (location) {
      case "/app/feed":
        this.setState({ active: "Feed" });
        break;
      case "/app/dashboard":
        this.setState({ active: "Mon compte" });
        break;
      case "/association/login":
        this.setState({ active: "Je suis une association" });
        break;
      case "/app/comment-ca-marche":
        this.setState({ active: "Comment ça marche ?" });
        break;
      default:
        this.setState({ active: "Feed" });
        break;
    }
  }

  showDrawer = () => {
    this.props.openCart();
  };

  onClose = () => {
    this.props.closeCart();
    this.setState({ collapsed: false });
  };

  toggleCollapsed = (active) => {
    if (active !== null) {
      this.setState({
        collapsed: !this.state.collapsed,
        active,
      });
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  renderMobileMenu = (array) => {
    const { active } = this.state;
    return array.map((x, index) => {
      if (x.static_website) {
        return (
          <a
            key={index}
            href={x.link}
            className="menu-item"
          >
            {x.value}
          </a>
        );
      } else {
        return (
          <Link
            key={index}
            onClick={() => this.toggleCollapsed(x.value)}
            to={x.link}
            className={active === x.value ? "menu-item active" : "menu-item"}
          >
            {x.value}
          </Link>
        );
      }
    });
  };

  renderCartLogo = (cart) => {
    return [
      <div
        key={0}
        onClick={() => this.showDrawer()}
        className={
          cart.length === 0 ? "render-cart-logo active" : "render-cart-logo"
        }
      >
        <i className="fad fa-2x fa-box-open"></i>
      </div>,
      <div
        key={1}
        onClick={() => this.showDrawer()}
        className={
          cart.length > 0 ? "render-cart-logo active" : "render-cart-logo"
        }
      >
        <i className="fad fa-2x fa-box-heart"></i>
        <sup
          style={{
            top: "-2em",
            fontFamily: "'Open Sans', sans-serif",
            marginLeft: 5,
          }}
        >
          {cart.length}
        </sup>
      </div>,
    ];
  };

  search = (data) => {
    if (this.state.collapsed) {
      this.toggleCollapsed(null);
    }
    this.props.search(data);
  };

  render() {
    const { children, user, cart, location, open_cart } = this.props;
    const { collapsed } = this.state;
    const mobile_array = [
      {
        link: "/app/feed",
        value: "Collectes",
		static_website: false,
      },
      {
        link: user ? "/app/dashboard" : "/app/login",
        value: user ? "Mon compte" : "Login",
		static_website: false,
      },
      {
        link: `${STATIC_URL}/faq-donateurs`,
        value: "FAQ donateurs",
		static_website: true,
      },
      {
        link: `${STATIC_URL}/lancer-une-collecte`,
        value: "Lancer une collecte",
		static_website: true,
      },
    ];

    return (
      <div style={{ overflowY: "scroll" }}>
        <div
          className="header"
          style={{
            backgroundImage: `url(${HeaderTopBG})`,
          }}
        >
          <Drawer
            title={
              <div className="mobile-menu">
                <div onClick={() => this.toggleCollapsed(null)}>
                  <MenuFoldOutlined style={{ fontSize: 30 }} />
                </div>
              </div>
            }
            placement="left"
            closable={true}
            onClose={() => this.onClose()}
            visible={collapsed}
            className="menu-drawer"
          >
            {/*<div className="menu-drawer-logo">
                <img src={"https://wisdana.ams3.digitaloceanspaces.com/logo_512.png"} alt="Wisdana" style={{ width: 100, marginLeft: 'auto', marginRight: 'auto' }}/>
              </div>*/}
            <Search
              placeholder=""
              onSearch={(value) => this.search(value)}
              className="search"
            />
            <div className="menu-mobile-container">
              {this.renderMobileMenu(mobile_array)}
              {user && (
                <Link key={0} to={"/app/signout"} className={"menu-item"}>
                  Se déconnecter
                </Link>
              )}
            </div>
          </Drawer>
          <div
            className="item mobile"
            onClick={() => this.toggleCollapsed(null)}
          >
            <MenuUnfoldOutlined style={{ fontSize: 30 }} />
          </div>
          <div className="item logo">
            <div className="first">
              <Link to={"/app/feed"}>
                <img src={Logo} alt="Wisdana" />
              </Link>
            </div>
          </div>
          <div className="item middle">
            <div className="second">
              <Search
                placeholder=""
                onSearch={(value) => this.search(value)}
                className="search"
              />
            </div>
          </div>
          <div className="item special">
            <div className="third">{this.renderCartLogo(cart)}</div>
            {user && (
              <div className="fourth">
                <Link to={"/app/dashboard"}>
                  <i className="fad fa-user-cog"></i>
                </Link>
              </div>
            )}
            {!user && (
              <div className="fourth">
                <Link to={"/app/login"}>
                  <i className="fad fa-user-secret"></i>
                </Link>
              </div>
            )}
          </div>
        </div>
        <Drawer
          title="Mes dons"
          placement="right"
          closable={true}
          onClose={() => this.onClose()}
          visible={open_cart}
          className="cart-drawer"
          headerStyle={{ textAlign: "center" }}
        >
          <Cart
            cart={cart}
            updateCart={(data) => this.props.updateCart(data)}
            onClose={() => this.onClose()}
            in_checkout={false}
          />
        </Drawer>
        <div className="mobile-handling">{children}</div>
        <Footer location={location} />
      </div>
    );
  }
}
