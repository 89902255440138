import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { notification } from 'antd';

export default class MotDePasse extends Component {
  static propTypes = {
      handleNewPassword: PropTypes.func.isRequired,
      error: PropTypes.oneOfType([
        PropTypes.oneOf([null]).isRequired,
        PropTypes.string.isRequired,
      ]),
  }

  static defaultProps = {
    handleNewPassword: () => {},
    error: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      password: null,
      password_check: null,
      old_password: null,
    }
  }

  changeOldPassword = (old_password) => {
    if (old_password === '') {
      this.setState({ old_password: null });
    }
    this.setState({ old_password });
  }

  changePassword = (password) => {
    if (password === '') {
      this.setState({ password: null });
    }
    this.setState({ password });
  }

  changePasswordCheck = (password_check) => {
    if (password_check === '') {
      this.setState({ password_check: null });
    }
    this.setState({ password_check });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if(this.validatePasswordCheck() !== null) return false;
    this.props.handleNewPassword({
      password: this.state.password,
      old_password: this.state.old_password,
    });
  }

  validatePasswordCheck = () => {
    if (this.state.password === null) {
      return null;
    } else if (this.state.password === this.state.password_check) {
      return null;
    } else {
      return <p className="formatError">Les mots de passe ne sont pas identiques</p>
    }
  }

  validatePassword = () => {
    const { password } = this.state;
    if (password === null) {
      return true;
    } else if (!/^.{8,}$/.test(password)) {
      return true;
    } else if(!(/^.*\d+.*$/.test(password))) {
      return true;
    } else if(!/^.*[A-Z]+.*$/.test(password)) {
      return true;
    }
    return false;
  }

  eightCharCheck = (password) => {
    if (/^.{8,}$/.test(password)) {
      return <p className="done"><CheckOutlined />  8 caractères minimum</p>;
    }
    return <p>8 caractères minimum</p>;
  }

  oneNumberCheck = (password) => {
    if (/^.*\d+.*$/.test(password)) {
      return <p className="done"><CheckOutlined />  1 chiffre</p>;
    }
    return <p>1 chiffre</p>;
  }

  oneMajCheck = (password) => {
    if (/^.*[A-Z]+.*$/.test(password)) {
      return <p className="done"><CheckOutlined />  1 majuscule</p>;
    }
    return <p>1 majuscule</p>;
  }

  render() {
    const  { error, success } = this.props;
    const { password, password_check, old_password } = this.state;

    if (success) {
      setTimeout(this.props.flushSuccess(), 1000);
    }

    return (
      <div className="informations-container">
        {success &&
          notification['success']({
            message: 'Changement de mot de passe',
            description: `Merci pour la mise à jour de ton mot de passe !`,
            duration: 1,
            placement: 'topRight'
          })
        }
        <div className="informations-block">
          <h3 className="title">
            Modifier mon mot de passe
          </h3>
        </div>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="div-input long">
            <label>Votre ancien mot de passe</label>
            <Input.Password
              placeholder="Ancien Mot de passe"
              name="old_password"
              value={old_password}
              onChange={(e) => this.changeOldPassword(e.target.value)}
            />
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>Votre nouveau mot de passe</label>
              <Input.Password
                placeholder="Mot de passe"
                name="password"
                value={password}
                onChange={(e) => this.changePassword(e.target.value)}
              />
            </div>
            <div className="div-input">
              <div className="password-checker">
                {this.eightCharCheck(this.state.password)}
                {this.oneNumberCheck(this.state.password)}
                {this.oneMajCheck(this.state.password)}
              </div>
            </div>
          </div>
          <div className="multiple-input">
            {this.state.password !== null ?
              <div className="div-input">
                <label>Confirmez votre nouveau mot de passe</label>
                <Input.Password
                  placeholder="Confirmation du mot de passe"
                  name="password_check"
                  value={password_check}
                  onChange={(e) => this.changePasswordCheck(e.target.value)}
                />
              </div>:null
            }
            <div className="div-input">
              {this.validatePasswordCheck()}
            </div>
          </div>
          <div className="submit">
            <button type="submit" disabled={this.validatePassword()  || this.state.password_check !== this.state.password ? true:false}>Changer mot de passe</button>
          </div>
        </form>

        {error &&
          <div className="errorDiv">
            <p className="formatError">{error}</p>
          </div>
        }
      </div>
    );
  }
}
