import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import Skeleton from './skeleton';
import CollecteCard from '../elements/collecteCard';

import { trackWindowScroll }from 'react-lazy-load-image-component';

import "../elements/style.scss";

class Feed extends Component {
  static propTypes = {
    feed: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    sousTheme: PropTypes.array.isRequired,
    color: PropTypes.string.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    feed: [],
    sousTheme: [],
    color: '',
    scrollPosition: {},
  };

  displayCollecte = (feed, sousTheme) => {
    return feed
    .filter(x => {
      if (sousTheme.length === 0) {
        return x;
      } else {
        if (sousTheme.includes(x.sous_theme.name)) {
          return x;
        }
      }
      return null;
    })
    .map(x => {
      return <CollecteCard key={x.id} data={x} color={this.props.color} scrollPosition={this.props.scrollPosition} />;
    });
  }

  render() {
    const { feed, sousTheme } = this.props;
    return (
      <div className="feedBlock">
        {this.displayCollecte(feed, sousTheme)}
      </div>
    );
  }
}

export default trackWindowScroll(Feed);
